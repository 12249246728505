import React, { useRef, useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import * as bodyPix from "@tensorflow-models/body-pix";
import Webcam from "react-webcam";
import violaModel from "../../model.png";

function Expg() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const loadingRef = useRef(null);
  const webcamBgRef = useRef(null);
  const [cameraStatus, setCameraStatus] = useState(false);

  const multiplier = 0.5;
  const outputStride = 16;
  const segmentationThreshold = 0.5;

  //const [contextPerson, setContextPerson] = useState(null);

  let contextPerson;
  let net = null;
  let currentBGIndex = 0;
  let screenMode;
  let webcam;
  let processingCam = null;

  const requestAnimFrame = (() => {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  const cancelAnimationFrame = () => {
    return window.cancelAnimationFrame || window.mozCancelAnimationFrame;
  };

  const clearContext = (obj) => {
    contextPerson.clearRect(0, 0, obj.current.width, obj.current.height);
  };

  const getVideo = async () => {
    loadingRef.current.style.display = "block";
    webcamBgRef.current.style.display = "none";
    contextPerson = canvasRef.current.getContext("2d");
    if (!cameraStatus && processingCam === null) {
      processingCam = "processing";

      clearContext(canvasRef);
      screenMode = window.innerWidth > window.innerHeight ? "l" : "p";

      if (net == null) {
        net = await bodyPix
          .load({
            architecture: "MobileNetV1",
            outputStride: outputStride,
            multiplier: multiplier,
            quantBytes: 2,
          })
          .catch((error) => {
            console.log(error);
          });
        console.log("webcam started");
        setCameraStatus(true);

        loadingRef.current.style.display = "none";
        webcamBgRef.current.style.display = "block";
        detectBody();
      } else {
        //$("#canvasRef").removeClass("d-none");
      }
    } else {
      setCameraStatus(false);
      cancelAnimationFrame();
      clearContext(canvasRef);
      console.log("webcam stopped");
    }
  };

  function detectBody() {
    if (
      typeof webcamRef.current === "undefined" ||
      webcamRef.current === null ||
      webcamRef.current.video.readyState !== 4
    ) {
      console.error("Webcam not started yet.");
      setTimeout(detectBody, 100);
      return;
    }

    if (!net) {
      console.error("Net object is null.");
      return;
    }

    net
      .segmentPerson(webcamRef.current.video, {
        flipHorizontal: false,
        internalResolution: "medium",
        segmentationThreshold: segmentationThreshold,
      })
      .catch((error) => {
        console.log(error);
      })
      .then((personSegmentation) => {
        if (personSegmentation != null) {
          drawBody(personSegmentation);
        }
      });
    requestAnimFrame(detectBody);
    //setTimeout(detectBody, 40);
  }

  function drawBody(personSegmentation) {
    if (screenMode == "l") {
      let canvas = document.createElement("canvas");
      canvas.width = webcamRef.current.video.videoWidth;
      canvas.height = webcamRef.current.video.videoHeight;
      let context = canvas.getContext("2d");
      context.drawImage(webcamRef.current.video, 0, 0);
      let imageData = context.getImageData(
        0,
        0,
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight
      );

      let pixel = imageData.data;
      for (let p = 0; p < pixel.length; p += 4) {
        if (personSegmentation.data[p / 4] == 0) {
          pixel[p + 3] = 0;
        }
      }
      context.imageSmoothingEnabled = true;
      context.putImageData(imageData, 0, 0);

      let imageObject = new Image();
      imageObject.onload = function () {
        clearContext(canvasRef);
        contextPerson.imageSmoothingEnabled = true;
        contextPerson.drawImage(
          imageObject,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      };
      imageObject.src = canvas.toDataURL();
    } else {
      contextPerson.drawImage(
        webcamRef.current.video,
        0,
        0,
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight
      );
      let imageData = contextPerson.getImageData(
        0,
        0,
        webcamRef.current.video.videoWidth,
        webcamRef.current.video.videoHeight
      );
      let pixel = imageData.data;
      for (let p = 0; p < pixel.length; p += 4) {
        if (personSegmentation.data[p / 4] == 0) {
          pixel[p + 3] = 0;
        }
      }
      contextPerson.imageSmoothingEnabled = true;
      contextPerson.putImageData(imageData, 0, 0);
    }
  }

  useEffect(() => {
    getVideo();
  }, [webcamRef]);
  return (
    <div className="gl-page">
      <div className="webcam-cont">
        <Webcam className="webcam" ref={webcamRef} width="640" height="480" />
        <div ref={webcamBgRef} className="webcam-bg">
          <img src={violaModel} />
        </div>
        <canvas
          ref={canvasRef}
          className="canv"
          width="640"
          height="480"
        ></canvas>
        <div ref={loadingRef} className="loading"></div>
      </div>
    </div>
  );
}

export default Expg;
