import React, { useState } from "react";
import axios from "axios";
import { validateEmail } from "../common/Func";
import spinner from "../spinner.gif";

const Subscribe = ({ cpage }) => {
  const [errMsg, setErrMsg] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setErrMsg(null);
    setLoading(true);
    if (!email || !validateEmail(email)) {
      setErrMsg("Please provide valid Email.");
      setLoading(false);
      return;
    }
    try {
      await axios({
        method: "post",
        url: "/api/msubs",
        data: { email },
      })
        .then((result) => {
          if (result.status === 200) {
            console.log(result.data);
            setMsg(
              "Thank you for signing up. You'll see us in your inbox soon."
            );
          } else {
            setErrMsg("Unable to connect to server, please try again later.");
          }
        })
        .catch((error) => {
          setErrMsg("Error: " + error);
        });
    } catch (err) {
      setErrMsg("Unable to connect to server, please try again later.");
    }
    setLoading(false);
  };

  return (
    <>
      {msg && (
        <div className="gl-form">
          <div className="successMsg">{msg}</div>
        </div>
      )}
      {!msg && (
        <>
          <form
            action=""
            className={
              cpage == "start"
                ? "gl-inline-form gl-inline-form--centered"
                : "gl-inline-form"
            }
            onSubmit={handleFormSubmit}
          >
            <div className="gl-form__item">
              <label htmlFor="join" className="screen-reader-text">
                Sign up
              </label>
              <input
                type="email"
                id="join"
                name="join"
                placeholder="Enter your email..."
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className={
                cpage == "start"
                  ? "gl-button gl-button--primary"
                  : "gl-button gl-button--primary gl-button--medium"
              }
            >
              <i className="fa fa-fw fa-paper-plane"></i>
              <span className="gl-inline-form__submit-label">Sign-up</span>
            </button>
            {loading && (
              <img
                src={spinner}
                width="60"
                height="60"
                alt="Loading..."
                style={{ verticalAlign: "middle" }}
              />
            )}
          </form>
          {errMsg && <div className="errMsg">{errMsg}</div>}
        </>
      )}
    </>
  );
};

export default Subscribe;
