import React, { useState, useEffect } from "react";

import Start from "./Start";
import First from "./First";
import Sec from "./Sec";
import Exp from "./Exp";
import Desktop from "./Desktop";

const Preview0000 = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    document.title = "GLYPiX - A Window to Zion";
  }, []);

  return (
    <>
      {(step == 0 || step == 4) && <Start step={step} setStep={setStep} />}
      {step == 1 && <First step={step} setStep={setStep} />}
      {step == 2 && <Sec step={step} setStep={setStep} />}
      {step == 3 && <Exp step={step} setStep={setStep} />}
      {step == 5 && <Desktop step={step} setStep={setStep} />}
    </>
  );
};

export default Preview0000;
