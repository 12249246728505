import React, { useState } from "react";
import axios from "axios";
import { validateEmail } from "../common/Func";
import spinner from "../spinner.gif";

const ContactForm = () => {
  const [contactErrMsg, setContactErrMsg] = useState(null);
  const [contactMsg, setContactMsg] = useState(null);
  const [contactLoading, setContactLoading] = useState(false);

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    signup: true,
  });

  const contactInputHandle = (e) => {
    const { name, value } = e.target;
    setContactForm({ ...contactForm, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setContactErrMsg(null);
    setContactLoading(true);
    if (
      !contactForm.name ||
      !contactForm.email ||
      !validateEmail(contactForm.email)
    ) {
      setContactErrMsg("Name and valid Email are required fields.");
      setContactLoading(false);
      return;
    }
    try {
      if (contactForm.signup) {
        axios({
          method: "post",
          url: "/api/msubs",
          data: { email: contactForm.email },
        })
          .then((result) => {})
          .catch((error) => {});
      }

      await axios({
        method: "post",
        url: "/api/contact",
        data: contactForm,
      })
        .then((result) => {
          if (result.status === 200) {
            console.log(result.data);
            setContactMsg("Thank you, your message has been received.");
          } else {
            setContactErrMsg(
              "Unable to connect to server, please try again later."
            );
          }
        })
        .catch((error) => {
          setContactErrMsg("Error: " + error);
        });
    } catch (err) {
      setContactErrMsg("Unable to connect to server, please try again later.");
    }
    setContactLoading(false);
  };

  return (
    <>
      {contactMsg && (
        <div className="gl-form">
          <div className="successMsg">{contactMsg}</div>
        </div>
      )}
      {!contactMsg && (
        <form className="gl-form" onSubmit={handleFormSubmit}>
          <div className="gl-form__item">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => contactInputHandle(e)}
              required
            />
          </div>
          <div className="gl-form__item">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={(e) => contactInputHandle(e)}
              required
            />
          </div>
          <div className="gl-form__item">
            <label htmlFor="subject">Subject</label>
            <select
              name="subject"
              id="subject"
              onChange={(e) => contactInputHandle(e)}
            >
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
          <div className="gl-form__item">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="3"
              onChange={(e) => contactInputHandle(e)}
            ></textarea>
          </div>
          <div className="gl-form__item">
            <input
              type="checkbox"
              value="no"
              id="signup"
              name="signup"
              onChange={() =>
                contactInputHandle({
                  target: {
                    name: "signup",
                    value: !contactForm.signup,
                  },
                })
              }
              defaultChecked={contactForm.signup}
            />
            <label htmlFor="signup">
              Join our mailing list and receive updates
            </label>
          </div>
          {contactErrMsg && <div className="errMsg">{contactErrMsg}</div>}
          <div className="gl-form-submit">
            <button type="submit" className="gl-button gl-button--primary">
              <i className="fa fa-paper-plane"></i> Send
            </button>

            {contactLoading && (
              <img
                src={spinner}
                width="60"
                height="60"
                alt="Loading..."
                style={{ verticalAlign: "middle" }}
              />
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default ContactForm;
