import * as React from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Glypix from "./components/Glypix";
import Exp from "./components/Exp";
import Expg from "./components/glyph/Expg";
import Expn from "./components/glyph/Expn";
import QrError from "./components/QrError";
import ReadQr from "./components/ReadQr";
import Glyp from "./components/Glyp";
import Detail from "./components/Detail";
import DetailZion from "./components/Zion";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import QrCodeGen from "./components/QrCodeGen";
import Preview0000 from "./components/previews/g0000/Preview";
import Preview0001 from "./components/previews/g0001/Preview";
import Redirect from "./components/Redirect";
import Viola from "./components/exps/Viola";
import NotFound from "./components/NotFound";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Safari from "./components/Safari";

const SystemRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="qr-reader" element={<ReadQr />} />
      <Route path="qr-error" element={<QrError />} />
      <Route path="glypix" element={<Glypix />} />
      <Route path="qr-gen" element={<QrCodeGen />} />
      <Route path="glyp/:code" element={<Glyp />} />
      <Route path="exp/:code" element={<Exp />} />
      <Route path="glypg" element={<Expg />} />
      <Route path="glypn" element={<Expn />} />
      <Route path="g0000" element={<Preview0000 />} />
      <Route path="preview" element={<Preview0001 />} />
      <Route path="g0001" element={<Preview0001 />} />
      <Route path="viola-desmond-experience" element={<Detail />} />
      <Route path="window-to-zion" element={<DetailZion />} />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="safari" element={<Safari />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function App() {
  const hideHeaderPaths = ["/preview", "/g0001", "/g0000"];
  const { pathname } = useLocation();
  return (
    <>
      {!hideHeaderPaths.includes(pathname) && (
        <header className="gl-header">
          <Header />
        </header>
      )}
      {hideHeaderPaths.includes(pathname) ? <SystemRoutes /> : <SystemRoutes />}

      {!hideHeaderPaths.includes(pathname) && (
        <footer className="gl-footer">
          <Footer />
        </footer>
      )}
    </>
  );
}

export default App;
