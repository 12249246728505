import React, { useState, useRef } from "react";

import { Link } from "react-router-dom";
import logo from "../../../images/logo-g0001.svg";
import logoGly from "../../../images/logo-glypix.svg";

const Desktop = ({ step, setStep }) => {
  return (
    <div className="gl-page gl-page--preview">
      <div className="gl-g0001-content">
        <div className="gl-guide-step">
          <div className="gl-guide-step__header">
            <div className="gl-wrapper gl-wrapper--narrow">
              <div className="gl-epic-logo-vertical">
                <div className="gl-epic-logo-vertical__wordmark">
                  <img
                    src={logo}
                    alt="Logo word mark for The Viola Desmond Experience"
                  />
                </div>
                <div className="gl-align-center">
                  <small>(Ver 1.0)</small>
                </div>
                <div className="gl-tag">
                  <div className="gl-tag__copy">Presented by</div>
                  <div className="gl-tag__logo">
                    <Link to="/">
                      <img src={logoGly} alt="Glypix logo" />
                    </Link>
                  </div>
                </div>
                <div className="gl-wrapper gl-wrapper--thin">
                  <div className="gl-align-center">
                    <p className="gl-heading-medium" style={{ color: "#ec008c" }}>
                      Sorry, experience is only accessible via mobile device. Try
                      again with your mobile device.
                    </p>
                    <Link to="/" className="gl-button gl-button--primary-not-hover">
                      <i className="fa fa-home"></i> Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
