import React, { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerF,
} from "@react-google-maps/api";

import pin0000 from "../images/pin-g0000.png";
import pin0001 from "../images/pin-g0001.png";

const MapContainer = () => {
  const initialMarkers = [
    {
      position: {
        lat: 44.654215,
        lng: -63.58632,
      },
      pin: pin0001,
      label: {
        color: "pink",
        text: "The Viola Desmond Experience",
        addr: "2300 Gottingen Street, Halifax, NS",
      },
      draggable: false,
    },
    {
      position: {
        lat: 44.651636,
        lng: -63.582738,
      },
      pin: pin0000,
      label: {
        color: "white",
        text: "A Window to Zion",
        addr: "5510 Falkland Street, Halifax, NS",
      },
      draggable: false,
    },
  ];

  const [activeInfoWindow, setActiveInfoWindow] = useState("");
  const [markers, setMarkers] = useState(initialMarkers);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 44.652934,
    lng: -63.584721,
  };

  const mapClicked = (event) => {
    console.log(event.latLng.lat(), event.latLng.lng());
  };

  const markerClicked = (marker, index) => {
    setActiveInfoWindow(index);
    //console.log(marker, index);
    return false;
  };

  const markerDragEnd = (event, index) => {
    console.log(event.latLng.lat());
    console.log(event.latLng.lng());
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAFdKQAqarLhhi1HB0n0OggrHRqUINoY6g">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onClick={mapClicked}
      >
        {markers.map((marker, index) => (
          <MarkerF
            key={index}
            position={marker.position}
            label=""
            draggable={marker.draggable}
            //onDragEnd={(event) => markerDragEnd(event, index)}
            onClick={(event) => markerClicked(marker, index)}
            icon={{
              url: marker.pin,
              scale: 2,
            }}
          >
            {activeInfoWindow === index && (
              <InfoWindow position={marker.position}>
                <div>
                  <h2 className="gl-heading-small">{marker.label.text}</h2>
                  <div>{marker.label.addr}</div>
                </div>
              </InfoWindow>
            )}
          </MarkerF>
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
