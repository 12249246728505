import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";

const Privacy = ({ step, setStep }) => {
  useEffect(() => {
    document.title = "GLYPiX - Privacy Policy";
    window.scrollTo(0, 0);
  });
  return (
    <div className="gl-page">
      <div className="gl-page gl-page--preview">
        <div className="gl-g0001-content">
          <div className="gl-guide-step">
            <div className="gl-guide-step__content">
              <div className="gl-wrapper">
                <div className="gl-layout">
                  <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
                    <h1 className="gl-heading-large">Privacy Policy</h1>
                    <p>
                      <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>.
                      (“Company,” “we,” or “us”) respects your privacy and is
                      committed to protecting it through this Privacy Policy.
                    </p>
                    <p>
                      Our privacy commitment is based on the ten principles
                      contained in the Personal Information Protection and
                      Electronic Documents Act (Canada) (“PIPEDA”).
                    </p>
                    <p>
                      This Privacy Policy governs your access to and use of,
                      including any content, functionality and services offered
                      on or through www.glypix.com, (the “Website”), whether as
                      a guest or as a registered user. This policy applies to
                      Personal Information we collect on the Website and in
                      email, text, forms or other electronic messages between
                      you and the Website.
                    </p>
                    <p>
                      Please read the Privacy Policy carefully before you start
                      to use the Website. By using the Website, you accept and
                      agree to be bound and abide by the Privacy Policy. If you
                      do not want to agree to the Privacy Policy, you must not
                      access or use the Website.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Personal Information
                      </h2>
                    </p>
                    <p>
                      Personal Information under PIPEDA is any information about
                      an identifiable individual but does not include the name,
                      title, business address or telephone number of an employee
                      of an organization.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Why do we collect Personal Information?
                      </h2>
                    </p>
                    <p>
                      We collect Personal Information in order to provide
                      different virtual experiences for you. In addition, we
                      distribute publications and offer educational
                      opportunities concerning GLYPiX experiences, which we
                      provide as a service to you and others in our community.
                    </p>
                    <p>
                      The Company collects your information in order to record
                      and support your participation in the activities you
                      select. If you register to download a PDF or resources,
                      sign up for our newsletter, and/or purchase a product from
                      us, we collect your information. We use this information
                      to track your preferences and to keep you informed about
                      the products and services you have selected to receive and
                      any related products and/or services.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        How do we collect personal information?
                      </h2>
                    </p>
                    <p>
                      We collect personal information only by lawful and fair
                      means, and only collect personal information that is
                      reasonably necessary for the legitimate purposes
                      identified and for which consent has been obtained.
                    </p>
                    <p>
                      <em>Information You Provide To Us.</em> The Website
                      provides various places for users to provide information.
                      We collect information that users provide by filling out
                      forms on the Website, communicating with us via contact
                      forms, responding to surveys, search queries on our search
                      feature, providing comments or other feedback, and
                      providing information when ordering a product or service
                      via the Website.
                    </p>
                    <p>
                      We use information you provide to us to deliver the
                      requested product and/or service, to improve our overall
                      performance, and to provide you with offers, promotions,
                      and information.
                    </p>
                    <p>
                      <em>
                        Information We Collect Through Automatic Tracking
                        Technology
                      </em>
                    </p>
                    <p>
                      As you navigate through our Website, we may use automatic
                      data collection technologies including Google Analytics to
                      collect certain information about your equipment, browsing
                      actions, and patterns. This will generally include
                      information about your location, your traffic pattern
                      through our Website, and any communications between your
                      computer and our Website. Among other things, we will
                      collect data about the type of computer you use, your
                      Internet connection, your IP address, your operating
                      system, and your browser type.
                    </p>
                    <p>
                      The information we collect automatically is used for
                      statistical data and will not include personal
                      information. We use this data to improve our Website and
                      our service offerings. To the extent that you voluntarily
                      provide personal information to us, our systems will
                      associate the automatically collected information with
                      your personal information.
                    </p>
                    <p>
                      <em>Use of Cookies And Pixels</em>
                    </p>
                    <p>
                      Similar to other commercial websites, our Website utilizes
                      a standard technology called “cookies” and server logs to
                      collect information about how our site is used.
                      Information gathered through cookies and server logs may
                      include the date and time of visits, the pages viewed,
                      time spent at our site, and websites visited just before
                      and just after our own, as well as your IP address.
                    </p>
                    <p>
                      The Company reserves the right to use technological
                      equivalents of cookies, including social media pixels.
                      These pixels allow social media sites to track visitors to
                      outside websites so as to tailor advertising messages
                      users see while visiting that social media website. The
                      Company reserves the right to use these pixels in
                      compliance with the policies of the various social media
                      sites.​
                    </p>
                    <p>
                      <em>Third Party Use Of Cookies</em>
                    </p>
                    <p>
                      Some content or applications, including advertisements, on
                      the Website are served by third-parties, including
                      advertisers, ad networks and servers, content providers,
                      and application providers. These third parties may use
                      cookies alone or in conjunction with web beacons or other
                      tracking technologies to collect information about you
                      when you use our website. The information they collect may
                      be associated with your personal information or they may
                      collect information, including personal information, about
                      your online activities over time and across different
                      websites and other online services. They may use this
                      information to provide you with interest-based
                      (behavioral) advertising or other targeted content.
                    </p>
                    <p>
                      <em>Email Information</em>
                    </p>
                    <p>
                      If you choose to correspond with us through email, we may
                      retain the content of your email messages together with
                      your email address and our responses. We provide the same
                      protections for these electronic communications that we
                      employ in the maintenance of information received online,
                      mail, and telephone. This also applies when you register
                      for our website, sign up through any of our forms using
                      your email address or make a purchase on this site. For
                      further information see the email policies below.
                    </p>
                    <p>
                      <em>Email Policies</em>
                    </p>
                    <p>
                      We are committed to keeping your e-mail address
                      confidential. We do not sell, rent, or lease our
                      subscription lists to third parties, and will not disclose
                      your email address to any third parties except as required
                      by law.
                    </p>
                    <p>
                      All e-mails sent by us will be in compliance with the
                      Canadian Anti-Spam Legislation (“CASL”), including clearly
                      stating who the e-mail is from and provide clear
                      information on how to contact the sender. In addition, all
                      e-mail messages will also contain concise information on
                      how to remove yourself from our mailing list so that you
                      receive no further e-mail communication from us. Users who
                      no longer wish to receive our newsletter or promotional
                      materials may opt-out of receiving these communications by
                      clicking on the unsubscribe link in the e-mail.
                    </p>
                    <p>
                      <em>Collection from Third Parties</em>
                    </p>
                    <p>
                      Sometimes we may obtain information about you from other
                      sources including:
                    </p>
                    <p>
                      <ul>
                        <li>Government agencies or departments;</li>
                        <li>
                          Professional Advisors including accountants, lawyers
                          and financial advisors;
                        </li>
                        <li>Real estate agents;</li>
                        <li>Financial institutions;</li>
                        <li>Insurance companies;</li>
                        <li>Credit Bureaus; or</li>
                        <li>
                          Other third parties who represent that they have the
                          right to disclose the information.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        How do we use your information?
                      </h2>
                    </p>
                    <p>
                      We use personal information for purposes of presenting our
                      Website and its contents to you, providing you with
                      information, providing you with offers for products and
                      services, providing you with information about your
                      subscriptions and products, carrying out any contract
                      between you and the Company, administering our business
                      activities, providing customer service, and making
                      available other items and services to our customers and
                      prospective customers.
                    </p>
                    <p>
                      From time-to-time, we may use the information you provide
                      to us to make you offers to purchase products and services
                      provided by third parties in exchange for a commission to
                      be paid to us by such third parties. Should you opt to
                      take part in such promotions, the third parties will
                      receive your information.
                    </p>
                    <p>
                      From time-to-time, we may use the information you provide
                      to us to display advertisements to you that are tailored
                      to your personal characteristics, interests, and
                      activities.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Disclosure of Information
                      </h2>
                    </p>
                    <p>
                      As a general rule, we do not sell, rent, lease or
                      otherwise transfer any information collected either
                      automatically or through your voluntary action.
                    </p>
                    <p>
                      We may disclose your personal information to our
                      subsidiaries, affiliates, and service providers for the
                      purpose of providing our services to you.
                    </p>
                    <p>
                      We may disclose your personal information to a third
                      party, including a lawyer or collection agency, when
                      necessary to enforce our terms of service or any other
                      agreement between you and the Company.
                    </p>
                    <p>
                      We may provide your information to any successor or assign
                      in the event of a merger, divestiture, restructuring,
                      reorganization, dissolution, or other sale or transfer of
                      some or all of the Company’s assets and/or business.
                    </p>
                    <p>
                      We may disclose information when legally compelled to do
                      so when we, in good faith, believe that the law requires
                      it, or for the protection of our legal rights, or when
                      compelled by a court or other governmental entity to do
                      so.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">Security Measures</h2>
                    </p>
                    <p>
                      We use appropriate security measures to protect against
                      loss, theft, unauthorized access, disclosure, use or
                      modification of personal information. Such measures will
                      vary depending on the sensitivity, amount, format, nature
                      and storage of the personal information and will involve,
                      as applicable, physical, organizational and electronic
                      security measures, including premises security, restricted
                      file access, technological safeguards including security
                      software and firewalls to prevent unauthorized computer
                      access, and password and security policies. In
                      communicating with us, you should be aware that e-mail is
                      not a fully secure medium.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Updating Information and Correcting Errors
                      </h2>
                    </p>
                    <p>
                      Since we use your personal information to provide goods or
                      services to you, it is important that the information be
                      accurate and up-to-date. If any of your information
                      changes, is inaccurate or incomplete, please inform us so
                      that we can make any necessary changes.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Access to Personal Information
                      </h2>
                    </p>
                    <p>
                      We will respond promptly to any request for access to your
                      personal information and will advise you of the cost, if
                      any, prior to the retrieval of such records or
                      information. We will not respond to requests for access to
                      personal information that are frivolous, vexatious or
                      repetitious. In certain circumstances, we may be unable to
                      provide access to some or all of the personal information
                      that we hold about you.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">Visitor GDPR Rights</h2>
                    </p>
                    <p>
                      If you are visiting this Website from within the European
                      Union (“EU”), you are entitled to certain information and
                      have certain rights under the General Data Protection
                      Regulation of the EU which include the following:
                    </p>
                    <ul>
                      <li>
                        We will retain the any information you choose to provide
                        to us until the earlier of: (a) you asking us to delete
                        the information, (b) our decision to cease using our
                        existing data providers, or (c) We decide that the value
                        in retaining the data is outweighed by the costs of
                        retaining it.
                      </li>
                      <li>
                        You have the right to request access to your data that
                        we store and the rights to either rectify or erase your
                        personal data.
                      </li>
                      <li>
                        You have the right to seek restrictions on the
                        processing of your data.
                      </li>
                      <li>
                        You have the right to object to the processing of your
                        data and the right to the portability of your data.
                      </li>
                      <li>
                        To the extent that you provided consent to the our
                        processing of your personal data, you have the right to
                        withdraw that consent at any time, without affecting the
                        lawfulness of processing based upon consent that
                        occurred prior to your withdrawal of consent.
                      </li>
                    </ul>
                    <p>
                      You have the right to lodge a complaint with a supervisory
                      authority that has jurisdiction over issues related to the
                      General Data Protection Regulation. We require only the
                      information that is reasonably required to enter into a
                      contract with you. We will not require you to provide
                      consent for any unnecessary processing as a condition of
                      entering into a contract with us.
                    </p>
                    <p>
                      For more information about your rights as a website
                      visitor from the EU and the GDPR, please see the European
                      Commission website here -
                      <a href="https://ec.europa.eu/commission/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules_en">
                        https://ec.europa.eu/commission/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules_en
                      </a>
                      .
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Privacy Policy Changes
                      </h2>
                    </p>
                    <p>
                      We may change our Privacy Policy from time to time without
                      notice. Please check our Website for the most up-to-date
                      version of the policy.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Questions, concerns or requests for access
                      </h2>
                    </p>
                    <p>
                      If you have any questions or concerns regarding our
                      Privacy Policy, please contact us as follows:
                    </p>
                    <p>
                      Brittney Alford
                      <br />
                      Privacy Officer
                      <br />
                      info@glypix.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
