import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import Grid from "@mui/material/Grid";

const QrCodeGen = (props) => {
  const [data, setData] = useState("");

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${data}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="gl-page">
      <Grid container>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} />
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <div>
            <label>Type text to generate QR code</label>
            <input
              type="text"
              onChange={(e) => setData(e.target.value)}
              value={data}
            />
          </div>
          {data !== "" && (
            <>
              <div style={{ background: "white", padding: "16px" }}>
                <QRCode
                  id="qr-gen"
                  value={data}
                  size={290}
                  level={"H"}
                  includeMargin={true}
                />
              </div>
              <p>
                <button type="button" onClick={downloadQRCode}>
                  Download This QR Code
                </button>
              </p>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default QrCodeGen;
