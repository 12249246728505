import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";

const Terms = ({ step, setStep }) => {
  useEffect(() => {
    document.title = "GLYPiX - Terms of Use";
    window.scrollTo(0, 0);
  });
  return (
    <div className="gl-page">
      <div className="gl-page gl-page--preview">
        <div className="gl-g0001-content">
          <div className="gl-guide-step">
            <div className="gl-guide-step__content">
              <div className="gl-wrapper">
                <div className="gl-layout">
                  <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
                    <h1 className="gl-heading-large">Terms of Use</h1>
                    <p>
                      The following Terms of Use are entered into by and between
                      You and <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>
                      .(“Company”, “we”, or “us”).
                    </p>
                    <p>
                      The following terms and conditions, together with any
                      documents they expressly incorporate by reference (“Terms
                      of Use”), govern your access to and use of www.glypix.com,
                      including any content, functionality and services offered
                      on or through www.glypix.com (the “Website”), whether as a
                      guest or a registered user.
                    </p>

                    <p>
                      <h2 className="gl-heading-medium">Copyright & Content</h2>
                    </p>
                    <p>
                      All content on the Website is protected by copyright,
                      trademark and other applicable laws, and are the property
                      of the <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>.
                      unless otherwise noted.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">All Rights Reserved</h2>
                    </p>
                    <p>
                      <em>
                        This website and its contents are protected by the
                        copyright laws of Canada and international treaties to
                        which Canada is a signatory. Except as otherwise
                        provided for under copyright law, this website and its
                        content may not be copied, published, distributed,
                        downloaded or otherwise stored in a retrieval system,
                        transmitted or converted, in any form or by any means,
                        electronic or otherwise, without the prior written
                        permission of the copyright owner.
                      </em>
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">Legal Disclaimer</h2>
                    </p>

                    <p>
                      The information on this Website is provided as general
                      information and is not GLYPiX advice.
                    </p>
                    <p>
                      The information on this Website operated by GLYPIX VIRTUAL
                      MEDIA ARCHIVE INC. is for general information and
                      education on historical events and creative activities
                      only.
                    </p>
                    <p>
                      By visiting this Website, you have not retained GLYPIX
                      VIRTUAL MEDIA ARCHIVE INC. to give professional advice and
                      the information on the Website should not be considered a
                      substitute for professional advice.
                    </p>
                    <p>
                      <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>. makes
                      no warranty as to the accuracy, validity, timeliness or
                      completeness of any information on this Website or any
                      Website that can be accessed through this Website.
                    </p>
                    <p>
                      <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>. assumes
                      no liability for any damages whatsoever, including,
                      without limitation, indirect, special, consequential,
                      punitive or incidental damages of any kind, whether
                      brought in contract or tort, arising out of or in
                      connection with this Website, the inability to use this
                      Website or the use, reliance upon, or performance of any
                      material contained in or accessed from this Website, or
                      any linked Website, even if we are expressly advised of
                      the possibility of such damages.
                    </p>
                    <p>
                      By using this Website, you agree to take full
                      responsibility for any harm or damage you suffer as a
                      result of the use, or non-use, of the information
                      available on this Website or the resources available for
                      use or download from this Website. You agree to use
                      judgment and conduct due diligence before taking any
                      action or implementing any action or program suggested or
                      recommended on this Website.
                    </p>
                    <p>
                      <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>. is not
                      responsible for any errors or omissions, or for the
                      results obtained from the use of any information on this
                      Website.
                    </p>
                    <p>
                      <strong>GLYPIX VIRTUAL MEDIA ARCHIVE INC</strong>. had
                      made every effort to produce and represent the content and
                      programs described on this Website. There is, however, no
                      guarantee that you will earn any money or save money using
                      the techniques and ideas. If revenue or sales figures are
                      presented on our Website and our other social media or
                      email channels, we are showcasing exceptional results,
                      which do not reflect the average experience. You should
                      not rely on any revenue, sales, or earnings information we
                      present as any kind of promise, guarantee, or expectation
                      of any level of success or earnings. Subject to our Refund
                      Policy, we provide content without any express or implied
                      warranties. By continuing to use our Website and content,
                      you agree that we are not responsible for any decision you
                      may make regarding any information presented or as a
                      result of purchasing any of our products or services. Any
                      claims made of actual earnings or examples of actual
                      results can be verified upon request.
                    </p>
                    <p>
                      Any testimonials presented on this Website from past
                      clients or customers of our products and services are
                      actual and truthful statements. By using this Website, you
                      specifically recognize and agree that the testimonials are
                      not a guarantee of results that you or anyone else will
                      obtain by using any products or services offered on this
                      Website.
                    </p>
                    <p>
                      Information on this Website may be changed or updated at
                      any time without notice.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">Privacy</h2>
                    </p>

                    <p>
                      We respect your privacy and follow the principles of
                      PIPEDA in the collection of Personal Information from you
                      on our Website. Please see our full text of our Privacy
                      Policy here https://glypix.com/privacy
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Email And Other Electronic Communications
                      </h2>
                    </p>

                    <p>
                      Visiting the Website or sending emails to the Company
                      constitutes electronic communications. You consent to
                      receive electronic communications and you agree that all
                      agreements, notices, disclosures, and other communications
                      that we provide to you electronically, via email and on
                      the Website, satisfy any legal requirement that such
                      communications be in writing.
                    </p>
                    <p>
                      We would be pleased to communicate with you by e-mail, and
                      there are various places on this Website that provide you
                      the ability to send an electronic communication to the
                      Company. Any such email or other electronic communication,
                      however, does not create a business relationship or any
                      contractual relationship. As set forth more fully in our
                      Privacy Policy, we will take reasonable steps to ensure
                      that any communications remain confidential, but we cannot
                      guarantee the security of such communications and cannot
                      guarantee that we would not be required to disclose such
                      communications as a result of a court order.
                    </p>

                    <p>
                      <h2 className="gl-heading-medium">User Communication </h2>
                    </p>

                    <p>
                      The Website may contain chat areas, news groups, forums,
                      communities, comment sections or other message or
                      communication facilities designed to enable you to
                      communicate with the public at large or with a group.
                    </p>
                    <p>
                      You agree that when using features of the Website that
                      allow for communication by you, that you will not defame,
                      abuse, harass, stalk, threaten or otherwise violate the
                      legal rights of others.
                    </p>
                    <p>
                      You agree not to publish, post, upload, distribute or
                      disseminate any inappropriate, profane, defamatory,
                      infringing, obscene, indecent or unlawful topic, name,
                      material or information, or upload files unless you own or
                      control the rights thereto or have received all necessary
                      consents.
                    </p>
                    <p>
                      You agree not to advertise or offer to sell or buy any
                      goods or services for any business purpose, or harvest or
                      otherwise collect information about others, including
                      e-mail addresses, without their consent or violate any
                      applicable laws or regulations.
                    </p>

                    <p>
                      The Company has no obligation to monitor the user
                      communications but reserves the right to review materials
                      posted to the Website and to remove any materials in its
                      sole discretion. The Company reserves the right to
                      terminate your access to any or all of the user
                      communication services at any time without notice for any
                      reason whatsoever and to disclose any information as
                      necessary to satisfy any applicable laws.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">
                        Changes To the Terms Of Use
                      </h2>
                    </p>
                    <p>
                      We may revise and update these Terms of Use from time to
                      time in our sole discretion. All changes are effective
                      immediately when we post them, and apply to all access to
                      and use of the Website thereafter. Your continued use of
                      the Website following the posting of revised Terms of Use
                      means that you accept and agree to the changes. You are
                      expected to check this page from time to time so you are
                      aware of any changes, as they are binding on you.
                    </p>

                    <p>
                      <h2 className="gl-heading-medium">Jurisdiction</h2>
                    </p>

                    <p>
                      This agreement between you, the user, and GLYPIX VIRTUAL
                      MEDIA ARCHIVE INC. shall be governed by, and interpreted
                      in accordance with, the laws of the province of Nova
                      Scotia and the laws of Canada applicable therein. You
                      hereby consent and submit to the exclusive jurisdiction of
                      the courts of the province of Nova Scotia in any
                      proceeding related to this agreement.
                    </p>
                    <p>
                      <h2 className="gl-heading-medium">Contact Information</h2>
                    </p>

                    <p>
                      We welcome your questions about the Terms of Use. You can
                      contact us here:
                    </p>
                    <p>
                      Brittney Alford
                      <br />
                      Policy Officer
                      <br />
                      info@glypix.com
                      <br />
                      902-237-6278
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
