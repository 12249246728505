import React, { useRef, useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import * as bodyPix from "@tensorflow-models/body-pix";
import Webcam from "react-webcam";
import violaModel from "../../model.mp4";
import cape from "../../cape.png";

function Expg() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const tmpCanvasRef = useRef(null);
  const loadingRef = useRef(null);
  const webcamBgRef = useRef(null);
  const modelVideoRef = useRef(null);
  const capeRef = useRef(null);
  const leftCapeRef = useRef(null);
  const rightCapeRef = useRef(null);
  const mirrorCont = useRef(null);
  const mirrorInCont = useRef(null);
  const webcamContRef = useRef(null);
  const [cameraStatus, setCameraStatus] = useState(false);

  const multiplier = 0.75;
  const outputStride = 16;
  const segmentationThreshold = 0.5;

  // cape raitos
  const capeRatio = 2.975;
  //const capeTopPosRatio = 0.34;
  const capeTopPosRatio = 1;
  const sideCapeRatio = 0.702;

  // mirror
  const mirrorLeft = 285;
  const mirrorInTop = 126;
  const mirrorInLeft = 95;
  let mirrorMissCnt = 0;
  let mirrorCnt = 0;

  let net = null;
  let processingCam = null;
  let videoWidth, videoHeight, screenMode, contextPerson, tmpContext;
  let videoLoaded = false;

  const getVideo = async () => {
    let ret = await startCamera();
    if (ret) {
      setCameraStatus(true);
      webcamBgRef.current.style.display = "block";
      await checkVideoLoaded();
      detectBody();
    }
  };

  const startCamera = async () => {
    await modelVideoRef.current.play();

    loadingRef.current.style.display = "block";
    webcamBgRef.current.style.display = "none";

    if (!cameraStatus && processingCam === null) {
      processingCam = "processing";

      screenMode = window.innerWidth > window.innerHeight ? "l" : "p";

      if (net == null) {
        net = await bodyPix
          .load({
            architecture: "MobileNetV1",
            outputStride: outputStride,
            multiplier: multiplier,
            quantBytes: 2,
          })
          .catch((error) => {
            console.log(error);
          });
        console.log("webcam started");
      }
      return true;
    } else {
      setCameraStatus(false);
      console.log("webcam stopped");
    }
    return false;
  };

  const checkVideoLoaded = async () => {
    if (videoLoaded) {
      return true;
    }
    if (
      typeof webcamRef.current === "undefined" ||
      webcamRef.current === null ||
      webcamRef.current.video.readyState !== 4 ||
      !net
    ) {
      console.error("Webcam not started yet.");
      await new Promise((r) => setTimeout(r, 1000));
      return await checkVideoLoaded();
    }

    const { innerWidth: screenWidth } = window;

    videoLoaded = true;
    loadingRef.current.style.display = "none";

    videoWidth = webcamRef.current.video.videoWidth;
    videoHeight = webcamRef.current.video.videoHeight;

    let widthRatio = videoHeight / videoWidth;

    if (videoWidth > screenWidth) {
      videoWidth = screenWidth;
      videoHeight = screenWidth * widthRatio;
    }

    webcamRef.current.video.width =
      canvasRef.current.width =
      tmpCanvasRef.current.width =
      modelVideoRef.current.width =
        videoWidth;

    webcamContRef.current.style.width = videoWidth + "px";

    webcamRef.current.video.height =
      modelVideoRef.current.height =
      canvasRef.current.height =
      tmpCanvasRef.current.height =
        videoHeight;

    webcamContRef.current.style.height = videoHeight + "px";

    contextPerson = canvasRef.current.getContext("2d", {
      willReadFrequently: true,
    });
    contextPerson.imageSmoothingEnabled = true;
    tmpContext = tmpCanvasRef.current.getContext("2d", {
      willReadFrequently: true,
    });

    return true;
  };

  const getParts = (personSegmentation) => {
    let parts = {};

    for (let i = 0; i < personSegmentation.allPoses[0].keypoints.length; i++) {
      let part = personSegmentation.allPoses[0].keypoints[i].part;
      let position = personSegmentation.allPoses[0].keypoints[i].position;
      let score = personSegmentation.allPoses[0].keypoints[i].score;
      if (score > 0.7) {
        if (part == "leftEye") {
          parts.leftEye = position;
        } else if (part == "rightEye") {
          parts.rightEye = position;
        } else if (part == "leftShoulder") {
          parts.leftShoulder = position;
        } else if (part == "rightShoulder") {
          parts.rightShoulder = position;
        }
      }
    }
    return parts;
  };

  const adjustCape = (personSegmentation) => {
    /*if (!parts) {
      return;
    }*/

    let parts = {};

    for (let i = 0; i < personSegmentation.allPoses[0].keypoints.length; i++) {
      let part = personSegmentation.allPoses[0].keypoints[i].part;
      let position = personSegmentation.allPoses[0].keypoints[i].position;
      let score = personSegmentation.allPoses[0].keypoints[i].score;
      if (score > 0.7) {
        if (part == "leftEye") {
          parts.leftEye = position;
        } else if (part == "rightEye") {
          parts.rightEye = position;
        } else if (part == "leftShoulder") {
          parts.leftShoulder = position;
        } else if (part == "rightShoulder") {
          parts.rightShoulder = position;
        }
      }
    }

    let top, left, width;
    let mleft = 0;
    let mtop = 0;
    let mleftCanv = 0;
    let mtopCanv = 0;

    if (parts.leftShoulder && parts.rightShoulder) {
      if (parts.leftEye && parts.rightEye) {
        mirrorCont.current.style.display = "block";
        mtop = parts.leftEye.y - 400;
        mleft = (parts.leftEye.x + parts.rightEye.x) / 2 - mirrorLeft;
        mtopCanv = mirrorInTop + mtop;
        mleftCanv = mirrorInLeft + mleft;
        mirrorCont.current.style.top = `${mtop}px`;
        mirrorCont.current.style.left = `${mleft}px`;
        canvasRef.current.style.top = `-${mtopCanv}px`;
        canvasRef.current.style.left = `-${mleftCanv}px`;
      }
      if (parts.leftShoulder.x < parts.rightShoulder.x) {
        left = parts.leftShoulder.x;
        width = parts.rightShoulder.x - parts.leftShoulder.x;
      } else {
        left = parts.rightShoulder.x;
        width = parts.leftShoulder.x - parts.rightShoulder.x;
      }

      if (parts.leftShoulder.y < parts.rightShoulder.y) {
        top = parts.leftShoulder.y;
      } else {
        top = parts.rightShoulder.y;
      }
      left -= 25;
      width += 50;

      let topPosTmp = width * capeTopPosRatio;
      capeRef.current.style.width = width + "px";
      capeRef.current.style.top = `${top - topPosTmp - mtopCanv}px`;
      capeRef.current.style.left = `${left - mleftCanv}px`;
      capeRef.current.style.height =
        leftCapeRef.current.style.height =
        rightCapeRef.current.style.height =
          `${width * capeRatio}px`;
      leftCapeRef.current.style.width = rightCapeRef.current.style.width = `${
        width * sideCapeRatio
      }px`;
      leftCapeRef.current.style.left = rightCapeRef.current.style.right = `-${
        width * sideCapeRatio - 3
      }px`;
    }
  };

  function detectBody() {
    tmpContext.drawImage(
      webcamRef.current.video,
      0,
      0,
      videoWidth,
      videoHeight
    );
    let frame = tmpContext.getImageData(0, 0, videoWidth, videoHeight);
    net
      .segmentPerson(frame, {
        flipHorizontal: false,
        internalResolution: "medium",
        segmentationThreshold: segmentationThreshold,
      })
      .catch((error) => {
        console.log(error);
      })
      .then((personSegmentation) => {
        if (personSegmentation != null) {
          let parts = null;
          if (personSegmentation.allPoses.length == 1) {
            mirrorMissCnt = 0;
            //parts = getParts(personSegmentation);
            adjustCape(personSegmentation);
          } else {
            mirrorMissCnt++;
            if (mirrorMissCnt > 5) {
              mirrorCont.current.style.display = "none";
            }
          }
          drawBody(personSegmentation, frame, parts);
        }
      });
  }

  function drawBody(personSegmentation, frame, parts) {
    //console.log(personSegmentation);
    tmpContext.drawImage(modelVideoRef.current, 0, 0, videoWidth, videoHeight);
    let frame2 = tmpContext.getImageData(0, 0, videoWidth, videoHeight);

    //let vidStartPoint = 44800;
    let vidStartPoint = 40 * videoWidth;
    /*if (gLeftEye) {
      vidStartPoint = (gLeftEye.y - 150) * videoWidth;
    }*/

    for (
      let i = vidStartPoint, vidPix = 0;
      i < frame.data.length / 4;
      i++, vidPix++
    ) {
      let r = frame2.data[vidPix * 4 + 0];
      let g = frame2.data[vidPix * 4 + 1];
      let b = frame2.data[vidPix * 4 + 2];

      /*let rf = frame.data[i * 4 + 0];
        let bf = frame.data[i * 4 + 2];
        let gf = frame.data[i * 4 + 1];*/

      if (personSegmentation.data[i] == 0 && !(r < 8 && g < 8 && b < 8)) {
        frame.data[i * 4 + 0] = frame2.data[vidPix * 4 + 0];
        frame.data[i * 4 + 1] = frame2.data[vidPix * 4 + 1];
        frame.data[i * 4 + 2] = frame2.data[vidPix * 4 + 2];
      }
    }

    contextPerson.putImageData(frame, 0, 0);

    setTimeout(detectBody, 5);
  }

  useEffect(() => {
    getVideo();
  }, [webcamRef]);
  return (
    <div className="gl-page">
      <div ref={webcamContRef} className="webcam-cont">
        <video
          ref={modelVideoRef}
          id="video2"
          className="video2"
          width="480"
          src={violaModel}
          muted
          loop
        ></video>
        <div ref={webcamBgRef} className="webcam-bg"></div>
        <canvas className="canv2" ref={tmpCanvasRef}></canvas>
        <Webcam
          className="webcam"
          ref={webcamRef}
          width="480"
          videoConstraints={{ facingMode: { exact: "environment" } }}
        />

        <div ref={mirrorCont} className="mirror-cont">
          <div ref={mirrorInCont} className="mirror-in-cont">
            <canvas ref={canvasRef} className="canv" width="480"></canvas>
            <div ref={capeRef} className="webcam-cape">
              <div ref={leftCapeRef} className="cbefore" />
              <div ref={rightCapeRef} className="cafter" />
            </div>
          </div>
        </div>

        <div ref={loadingRef} className="loading"></div>
      </div>
    </div>
  );
}

export default Expg;
