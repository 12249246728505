import * as React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="gl-wrapper">
      <div className="gl-footer__content">
        <div className="gl-layout gl-layout--middle gl-layout--reverse">
          <div className="gl-layout__item col-1/1 col-1/2@tablet">
            <div className="gl-social-menu">
              <ul className="gl-list-inline">
                <li>
                  <a
                    href="https://www.facebook.com/glypix"
                    className="gl-button-icon"
                  >
                    <i
                      className="fab fa-facebook-square"
                      aria-hidden="true"
                    ></i>
                    <span className="screen-reader-text">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/glypix_history/"
                    className="gl-button-icon"
                  >
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                    <span className="screen-reader-text">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@glypix_history"
                    className="gl-button-icon"
                  >
                    <i className="fab fa-tiktok" aria-hidden="true"></i>
                    <span className="screen-reader-text">TikTok</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Glypix_history"
                    className="gl-button-icon"
                  >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                    <span className="screen-reader-text">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@glypix/featured"
                    className="gl-button-icon"
                  >
                    <i className="fab fa-youtube" aria-hidden="true"></i>
                    <span className="screen-reader-text">Youtube</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="gl-layout__item col-1/1 col-1/2@tablet">
            <div className="gl-footer__nav">
              <div className="gl-footer-menu">
                <nav>
                  <ul className="gl-list-inline">
                    <li>
                      <Link to="/terms">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="gl-footer__copy">
              &copy; 2022 Glypix Virtual Media Archive. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
