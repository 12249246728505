import * as React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

const QrError = () => {
  return (
    <div className="gl-page">
      <Grid container>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} />
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <main>
            <h2>Invalid QR</h2>
            <p></p>
          </main>
          <nav>
            <Link to="/qr-reader">Try Again!</Link>
          </nav>
        </Grid>
      </Grid>
    </div>
  );
};

export default QrError;
