import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { usePreviewData } from "@opengraphninja/react";
import { Box } from "@mui/material";
import { red } from "@mui/material/colors";
import MapContainer from "./MapContainer";
import ContactForm from "./ContactForm";

//import g0001Thumb from "../images/image-g0001-thumbnail.jpg";
import g0001Thumb from "../images/image-g0001-thumbnail.jpeg";
import g0001BTSThumb from "../images/image-g0001-behind-the-scenes-thumbnail.jpg";
import g0001Icon from "../images/icon-g0001.svg";
import g0001Logo from "../images/logo-g0001.svg";
import g0001Story from "../images/image-g0001-story.jpeg";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Glyp = () => {
  const params = useParams();
  const navigate = useNavigate();
  const gkey = "AIzaSyAFdKQAqarLhhi1HB0n0OggrHRqUINoY6g";
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  const showcases = [
    {
      title:
        "Global News - Art installation honouring Viola Desmond unveiled in north end Halifax",
      url: "https://globalnews.ca/news/9300985/viola-desmond-art-installation-halifax/",
      img: "https://globalnews.ca/wp-content/uploads/2022/11/viola.jpg?quality=85&strip=all&w=720&h=379&crop=1",
      desc: "",
    },
    {
      title:
        "Saltwire - Viola Desmond legacy comes to life in her Gottingen Street neighbourhood",
      url: "https://www.saltwire.com/atlantic-canada/news/viola-desmond-legacy-comes-to-life-in-her-gottingen-street-neighbourhood-100798818/",
      img: "https://saltwire.imgix.net/2022/11/23/viola-desmond-legacy-comes-to-life-on-gottingen-street-1.jpg?cs=srgb&amp;w=1200&amp;h=630&amp;fit=crop&amp;auto=enhance%2Ccompress%2Cformat",
      desc: "",
    },
    {
      title:
        "The Coast - New Viola Desmond memorial on Gottingen Street celebrates her legacy perfectly",
      url: "https://www.thecoast.ca/halifax/new-viola-desmond-memorial-on-gottingen-street-celebrates-her-legacy-perfectly/Content?oid=29782659",
      img: "https://media2.fdncms.com/thecoast/imager/u/slideshow/29782663/img_6808.jpg",
      desc: "",
    },
    {
      title:
        "Canada Today - How Viola Desmond's drawing room was reimagined through art",
      url: "https://canadatoday.news/ns/how-viola-desmonds-drawing-room-was-reimagined-through-art-55042/",
      img: "https://canadatoday.news/ns/img/sites/10/2022/07/featuredimg.png",
      desc: "",
    },
    {
      title:
        "CTV News - Art installation launches in Halifax honouring N.S. civil rights icon Viola Desmond",
      url: "https://atlantic.ctvnews.ca/art-installation-launches-in-halifax-honouring-n-s-civil-rights-icon-viola-desmond-1.6168333",
      img: "https://www.ctvnews.ca/content/dam/ctvnews/en/images/2022/11/25/viola-desmond-1-6168338-1669382186503.jpg",
      desc: "",
    },
    {
      title:
        "Signal Halifax - Ceremony unveils art installation commemorating Viola Desmond",
      url: "https://signalhfx.ca/ceremony-unveils-art-installation-commemorating-viola-desmond/",
      img: "https://signalhfx.ca/wp-content/uploads/2022/11/Viola-D.-art1-990x557.jpg",
      desc: "",
    },
    {
      title:
        "CBC - How Viola Desmond's salon space has been reimagined through art",
      url: "https://www.cbc.ca/news/canada/nova-scotia/halifax-artist-creates-interactive-simulation-of-viola-desmond-s-salon-1.6665649",
      img: "https://i.cbc.ca/1.6665654.1669507062!/fileImage/httpImage/image.jpg_gen/derivatives/16x9_620/marven-nelligan.jpg",
      desc: "",
    },
  ];

  useEffect(() => {
    document.title = "GLYPiX - Details of The Viola Desmond Experience";
    window.scrollTo(0, 0);
  });

  return (
    <div className="gl-page">
      <section className="gl-section gl-section--small">
        <div className="gl-embellishment one"></div>
        <div className="gl-row gl-row--large">
          <div className="gl-wrapper">
            <div className="gl-layout">
              <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
                <div className="gl-page-thumbnail">
                  <img src={g0001Thumb} alt="" />
                </div>

                <h1>
                  <span className="gl-epic-logo">
                    <span className="gl-epic-logo__icon">
                      <img src={g0001Icon} alt="" />
                    </span>
                    <span className="gl-epic-logo__wordmark">
                      <img src={g0001Logo} alt="" />
                    </span>
                  </span>
                  <span className="screen-reader-text">
                    The Viola Desmond Experience
                  </span>
                </h1>
                <br />
                <h2 className="gl-heading-medium">
                  Have a seat in the chair at the beauty studio of entrepreneur
                  and Canadian civil rights icon, Viola Desmond, and hear her
                  amazing story on how she impacted the Black beauty industry in
                  Canada.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
              <div className="gl-row">
                <div className="gl-layout">
                  <div className="gl-layout__item col-1/1 col-1/2@tablet">
                    <h3 className="gl-heading-small">Installation</h3>
                    <p>
                      <span>Charlie Marven</span>
                      <br />
                      <span>2022</span>
                      <br />
                      <span>
                        Digital art on wall panels, custom salon chair, stylized
                        concrete overlay
                      </span>
                    </p>
                  </div>

                  <div className="gl-layout__item col-1/1 col-1/2@tablet">
                    <h3 className="gl-heading-small">Production</h3>
                    <p>
                      <span>2023</span>
                      <br />
                      <span>Web AR, interactive film</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="gl-layout">
                <div className="gl-row gl-row--tiny">
                  <div className="gl-layout__item col-1/1">
                    <h2 className="gl-heading-small">Starring</h2>
                    <p>Meaghan Ryan as Viola Desmond</p>
                  </div>
                </div>
                <div className="gl-row gl-row--tiny">
                  <div className="gl-layout__item col-1/1">
                    <h2 className="gl-heading-small">
                      Written and Directed by
                    </h2>
                    <div className="gl-row gl-row--small">Charlie Marven</div>
                  </div>
                </div>
              </div>

              <details className="gl-row">
                <summary>Full credits</summary>

                <div className="gl-row">
                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Assistant Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Casting Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Executive Producer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Producer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Production Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Location Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Filming Consultant
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Sobaz Benjamin
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Screenplay Editor
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Co-editor
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Casting</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Accomplice Content Supply Co.
                    </div>
                  </div>
                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Account Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Bob Mills
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Casting Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Kate Devlin
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Cinematography</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      North Window Productions
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Marvelous Productions
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Camera Operator
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dario Lozano
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Boom Operator
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Annaka Gale
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Sound Mixer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Annaka Gale
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Gaffer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dario Lozano
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Grips
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dario Lozano
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Annaka Gale
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dyrell Nelligan
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Pre-editing
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Editing & VFX
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Annaka Gale
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dario Lozano
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Art Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Set Designer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Props Master
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Storyboards
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Interactive Composition
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Documenting Photography
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dyrell Nelligan
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Makeup</h2>
                    </div>

                    <div className="gl-row gl-row--tiny">
                      Tima's Moblie Makeup Services
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Makeup Artist
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Fatuma Marsman
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Hair Styling</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Unity Wigs and Hair
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Hair Designer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Hair Supervisor
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Nadine Sparks
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Hairdresser
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Adell Smith
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Wardrobe</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">Vivian Moderna</div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Costume Designer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Artwork Installation</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">Mattatall Signs</div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Account Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dan Russell
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Installation
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Kyle Barrett
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Devan Pallister
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Viola's Chair</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Projectworks Artistry 3312760 Nova Scotia Ltd
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Fabricator
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Todd Vassallo
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Marketing</h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Bryce Crosby Consulting
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Marketing Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Bryce Crosby
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">
                        GLYPiX Promotional Material
                      </h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Marvelous Productions
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Jennex Productions
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Art Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Videography
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Joel Jennex
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">
                        The Viola Desmond Experience - Behind the Scenes
                      </h2>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Marvelous Productions
                    </div>
                    <div className="gl-row gl-row--tiny">
                      Jennex Productions
                    </div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Producer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Producer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Editor
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Joel Jennex
                      </div>
                    </div>

                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Featuring
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Brittney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Annaka Gale
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dan Russell
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Dario Lozano
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Fatuma Marsman
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Meaghan Ryan
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Nadine Sparks
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Sobaz Benjamin
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Suzy Hansen
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet" />
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Todd Vassallo
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--small">
                    <div className="gl-row gl-row--tiny">
                      <h2 className="gl-heading-small">Interactive Media</h2>
                    </div>

                    <div className="gl-row gl-row--tiny">GLYPiX</div>

                    <div className="gl-row gl-row--tiny">Marvelous Design</div>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Creative Director
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Project Manager
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Britney Alford
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        User Experience
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Stephanie Lummis
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Back-end Developer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Akash Deep Sharma
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Front-end Developer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Lead Designer & Illustrator
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Charlie Marven
                      </div>
                    </div>
                    <div className="gl-row gl-row--tiny">
                      <div className="gl-layout__item col-1/1 col-1/3@tablet">
                        Junior Designer
                      </div>
                      <div className="gl-layout__item col-1/1 col-2/3@tablet">
                        Samtrez Crawley
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Quality Assurance</h2>
                  </div>
                  <div className="gl-row gl-row--tiny">Stephanie Lummis UX</div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Archival Material</h2>
                  </div>
                  <div className="gl-layout">
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        <div className="gl-row gl-row--tiny">
                          "Viola Desmond: Her Life and Times"
                        </div>
                        <em>
                          Graham Reynolds & Wanda Robson <br />
                          Roseway Publishing, 2018
                        </em>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Images Provided by</h2>
                  </div>
                  <div className="gl-layout">
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Joe Robson and Family
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Nova Scotia Archives
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        University of King's College Halifax
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Cape Breton University
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">CBC</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Canadian Museum for Human Rights
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Allnumis</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Shutterstock</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Snipstock</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">iStock</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Vecteezy</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Yahoo Finance</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Unsplash</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">GraphicsFuel</div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Music</h2>
                  </div>
                  <div className="gl-layout">
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--small">
                        <div className="gl-row gl-row--tiny">"Shield"</div>
                        <em>
                          Written by Quinten John Coblentz, Wesley Eugene Smith,
                          Allyssa Lynnea Patterson <br />
                          Performed by WEARETHEGOOD, Lynnea <br />
                          Courtesy of Artlist Original Music, 2021
                        </em>
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--small">
                        <div className="gl-row gl-row--tiny">"Chillax"</div>
                        <em>
                          Written by Curtis Cole <br />
                          Performed by Curtis Cole <br />
                          Courtesy of Artlist Original Music, 2022
                        </em>
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--small">
                        <div className="gl-row gl-row--tiny">
                          "Shield Instrumental"
                        </div>
                        <em>
                          Written by Quinten John Coblentz, Wesley Eugene Smith,
                          Allyssa Lynnea Patterson <br />
                          Performed by WEARETHEGOOD, Lynnea <br />
                          Courtesy of Artlist Original Music, 2021
                        </em>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Additional Footage</h2>
                  </div>
                  <div className="gl-layout">
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--small">
                        <div className="gl-row gl-row--tiny">
                          "London: US artist launches first exhibition which
                          features artwork in Augmented Reality | World News"
                        </div>
                        <em>
                          WION. January 21, 2022). Video <br />
                          https://youtu.be/M-RwScwQ_hw
                        </em>
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--small">
                        <div className="gl-row gl-row--tiny">
                          "Stonewall Forever - A Project by The Center with
                          Support from Google."
                        </div>
                        <em>
                          Google. February 11, 2023. Video <br />
                          https://youtu.be/floNiVwL59o.
                        </em>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gl-row">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Special Thanks</h2>
                  </div>

                  <div className="gl-layout">
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        The Community of North End Halifax
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        iMOVe Arts Association
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Nova Scotia Community College
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Meyvn Sustainable Apparel
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Vogue Men's Wear & Tailoring
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">McInnis Cooper</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Premier Accounting And Tax Inc.
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        The Family of Viola Desmond
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Joe and Wanda Robson
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Counsillor Lindell Smith
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Raymond Pancura</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Marika Paris</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Thomas Elliot</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Donna Nelligan</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Joshua Baughman</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Carol-Ann Robarts
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Jack Pepper</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Jake Andrew Burke
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Tiffannie Ellingwood
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Morgan Cobb</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">
                        Ashley Peachey-Roane
                      </div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Pauline Bowers</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Joe Ryan</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Roy Fraser</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Justin Burke</div>
                    </div>
                    <div className="gl-layout__item col-1/1 col-1/2@tablet">
                      <div className="gl-row gl-row--tiny">Nimbus</div>
                    </div>
                  </div>
                </div>

                <div className="gl-row gl-row--large">
                  <div className="gl-row gl-row--tiny">
                    <h2 className="gl-heading-small">Partners</h2>
                  </div>
                  <div className="gl-row gl-row--tiny">
                    Atlantic Canada Opportunities Agency
                  </div>
                  <div className="gl-row gl-row--tiny">
                    North End Business Association
                  </div>
                  <div className="gl-row gl-row--tiny">
                    Viola Desmond Legacy Committee
                  </div>
                </div>
              </details>

              <div className="gl-row">
                &copy; GLYPiX Virtual Media Archive Inc 2023. All rights
                reserved.
              </div>
              <div className="gl-row">
                This experience is protected under the laws of Canada and other
                countries. Unauthorized duplication, distribution, or exhibition
                may result in civil liability and criminal prosecution.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="location"
        className="gl-section gl-section--small gl-section--epic-location"
      >
        <div className="gl-wrapper gl-row">
          <h2 className="gl-heading-large">Location</h2>
          <div>2300 Gottingen Street, Halifax, NS</div>
        </div>
        <div className="gl-wrapper">
          <div className="gl-map">
            <MapContainer />
          </div>
        </div>
      </section>

      <section
        id="details"
        className="gl-section gl-section--small gl-section--epic-details"
      >
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
              <div className="gl-row">
                <h2 className="gl-heading-large">Details</h2>
                <h3 className="gl-heading-medium gl-row gl-row--tiny">
                  Experience
                </h3>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-clock"></i>
                    </span>
                    Duration - 5mins
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-headphones"></i>
                    </span>
                    Best with headphones
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-on-site"></i>
                    </span>
                    On site only
                  </li>
                </ul>
              </div>

              <div className="gl-row">
                <h3 className="gl-heading-medium gl-row gl-row--tiny">
                  Compatibility
                </h3>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-mobile-device"></i>
                    </span>
                    Mobile web browser
                  </li>
                </ul>
              </div>

              <div className="gl-row">
                <h3 className="gl-heading-medium gl-row gl-row--tiny">
                  Technology
                </h3>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-mobile-device-rear-cam"></i>
                    </span>
                    Mobile device camera
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="gl-icon icon-touch-app"></i>
                    </span>
                    Interactive film
                  </li>
                </ul>
              </div>

              <details>
                <summary>Full details</summary>
                <div className="gl-row">
                  <h3 className="gl-heading-medium gl-row gl-row--tiny">
                    Accessibility
                  </h3>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-accessible"></i>
                      </span>
                      Wheelchair accessible
                    </li>
                  </ul>
                </div>

                <div className="gl-row">
                  <h3 className="gl-heading-medium gl-row gl-row--tiny">
                    How to get here
                  </h3>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-bus"></i>
                      </span>
                      Closest bus stops 6775, 6786
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-parking"></i>
                      </span>
                      Temporary street parking available
                    </li>
                  </ul>
                </div>

                <div className="gl-row">
                  <h3 className="gl-heading-medium gl-row gl-row--tiny">
                    Best time to visit
                  </h3>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-traffic"></i>
                      </span>
                      Outside of rush hour
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-clock"></i>
                      </span>
                      Early evenings
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-calendar-range"></i>
                      </span>
                      Weekends
                    </li>
                  </ul>
                </div>

                <div className="gl-row">
                  <h3 className="gl-heading-medium gl-row gl-row--tiny">
                    Seasonal Considerations
                  </h3>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-weather"></i>
                      </span>
                      Uncovered outdoor experience
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="gl-icon icon-umbrella"></i>
                      </span>
                      Subject to precipitation
                    </li>
                  </ul>
                </div>
              </details>
            </div>
          </div>
        </div>
      </section>

      <section
        id="story"
        className="gl-section gl-section--small gl-section--epic-story"
      >
        <div className="gl-wrapper">
          <div className="gl-layout gl-layout--huge gl-layout--reverse">
            <div className="gl-layout__item col-1/1 col-1/2@tablet">
              <p>
                <img src={g0001Story} alt="Viola Desmond" />
              </p>
            </div>
            <div className="gl-layout__item col-1/1 col-1/2@tablet">
              <h2 className="gl-heading-large">Remembering an Icon</h2>
              <p>
                Viola Irene Desmond was a prominent Black Canadian businesswoman
                who became a nationally recognized civil rights icon. The
                daughter of James and Gwendolin Davis, she was born July 6th,
                1914 and lived in the North End of Halifax, Nova Scotia, where
                she built her hair and cosmetics empire from the ground up. This
                happened during a time when segregation was prevalent and
                opportunities for Black Nova Scotians were extremely limited.
              </p>
              <p>
                A lover of education, she began her career as a school teacher
                at the age of 19. However, after reading a magazine article and
                learning about the success of Madam C. J. Walker, one of the
                pillars of the Black beauty industry in the United States, Viola
                saw an opportunity to open the first hair studio in Halifax that
                would cater to Black women.
              </p>
              <p>
                Vi's Studio of Beauty Culture opened for business on Gottingen
                Street in 1937. Not long after, Viola launched her cosmetics
                line, specially formulated for darker skin complexions, called
                Sepia Beauty Products by Viola Desmond. She went on to establish
                the Desmond School of Beauty Culture in 1944, the only school in
                Nova Scotia where Black women could train to become beauticians.
              </p>
              <p>
                On November 8th, 1946, during an incident at the Roseland
                Theatre in New Glasgow, Nova Scotia, Viola was assaulted and
                wrongfully arrested as a result of racial discrimination. Today,
                thanks to the efforts of her sister, renowned author and
                educator Wanda Robson (1926 – 2022), she is recognized as a
                national hero for her bravery, and composure, in the face of
                racial prejudice.
              </p>
              <p>
                Viola Desmond passed away on February 7, 1965, at the age of 50
                in New York City.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* NOTE:
    The links in the showcase will only serve as external
    links to press articles, or a YouTube video player overlay,
    similar to our preview video on the front page. */}

      <section
        id="showcase"
        className="gl-section gl-section--small gl-section--epic-showcase"
      >
        <div className="gl-wrapper">
          <h2 className="gl-heading-large">Showcase</h2>
          <div className="gl-carousel">
            <ul className="gl-carousel__list">
              <li className="gl-carousel__item">
                <div className="gl-card gl-card--alt gl-card--video">
                  <div className="gl-card__content">
                    <div className="gl-card__thumbnail">
                      <a href="#" onClick={toggleVideo}>
                        <img
                          src={g0001BTSThumb}
                          alt="Thumbnail for {Epic Title}"
                        />
                      </a>
                    </div>
                    <div className="gl-card__details">
                      <h3 className="gl-card__title">
                        <a href="#" onClick={toggleVideo}>
                          <i className="gl-icon icon-g0001"></i>
                          The Viola Desmond Experience - Behind the Scenes
                        </a>
                      </h3>
                      <div className="gl-card__location"></div>
                    </div>
                  </div>
                  <div className="gl-card__hover"></div>
                </div>
              </li>
              {showcases.map(({ title, url, img, desc }, idx) => (
                <li className="gl-carousel__item" key={idx}>
                  <div className="gl-card gl-card--alt">
                    <div className="gl-card__content">
                      <div className="gl-card__thumbnail">
                        <a target="_blank" href={url}>
                          <img src={img} alt={title} />
                        </a>
                      </div>
                      <div className="gl-card__details">
                        <h3 className="gl-card__title">
                          <a target="_blank" href={url}>
                            <i className="gl-icon icon-g0001"></i>
                            {title}
                          </a>
                        </h3>
                        <div className="gl-card__location"></div>
                        {desc !== "" && (
                          <div className="gl-card__description">{desc}</div>
                        )}
                      </div>
                    </div>
                    <div className="gl-card__hover"></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>{/* INSTAGRAM FEED */}</div>
        </div>
      </section>

      <section id="contact" className="gl-section gl-section--small">
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-3/5@desktop">
              <h2 className="gl-heading-large">
                What story do you want to celebrate?
              </h2>
              <div className="gl-content">
                <p>
                  Contact us to find out how you can be involved, and help bring
                  the next Glypix project to life. We’re looking to establish
                  meaningful partnerships with communities, government
                  organizations, developers, culture and heritage groups,
                  museums, galleries and educators.
                </p>
                <p>
                  If you're a talented creative professional looking for a
                  challenge, we'd love to chat.
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="gl-embellishment three"></div>
      </section>
      {showVideo && (
        <div className="gl-preview-video" onClick={toggleVideo}>
          <div className="gl-wrapper">
            <div className="gl-preview-video__ar">
              <iframe
                  width="1280"
                  height="720"
                  src="https://www.youtube.com/embed/_4xFVlEd7lQ?autoplay=1"
                  title="The Viola Desmond Experience - Behind the Scenes"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="gl-align-center close-cont">
            <a href="#" onClick={toggleVideo}>
              &times; Close
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Glyp;
