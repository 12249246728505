import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

const ReadQr = (props) => {
  const [data, setData] = useState("No Data");
  const navigate = useNavigate();

  useEffect(() => {
    if (data == "g0000" || data == "g0001") {
      if (data == "g0001") {
        navigate("/g0001");
        return;
      }
      localStorage.setItem("exp", data);
      navigate("/exp/" + data);
    }
  }, [data]);

  return (
    <div className="gl-page">
      <Grid container>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} />
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <div>
            <h2 className="gl-heading-large">Scan QR Code</h2>
            {data != "No Data" && data != "g0000" && data != "g0001" && (
              <div class="error">Invalid QR code: {data}</div>
            )}
            <div className="qr-cont">
              <QrReader
                constraints={{
                  facingMode: "environment",
                }}
                onResult={(result, error) => {
                  if (!!result) {
                    setData(result?.text);
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                style={{ width: "100%" }}
              />
              <div className="qr-mask">
                <div className="scan" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReadQr;
