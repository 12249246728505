import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";
import logoGly from "../../../images/logo-glypix.svg";
import sample from "../../../images/vid/g0001-beta.webm";
import Webcam from "react-webcam";

const Exp = ({ step, setStep }) => {
  const webcamRef = useRef(null);
  const vidRef = useRef(null);

  const [playSt, setPlaySt] = useState(true);
  const [vidConst, setVidConst] = useState("environment");

  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  const [size, setSize] = useState({
    width: screenWidth,
    height: screenHeight,
  });

  const adjustCamera = () => {
    if (
      typeof webcamRef.current === "undefined" ||
      webcamRef.current === null ||
      webcamRef.current.video.readyState !== 4
    ) {
      setTimeout(() => {
        adjustCamera();
      }, 100);
      return;
    }

    let videoWidth = webcamRef.current.video.videoWidth;
    let videoHeight = webcamRef.current.video.videoHeight;

    let widthRatio = videoHeight / videoWidth;
    let heightRatio = videoWidth / videoHeight;

    videoWidth = screenWidth;
    videoHeight = screenWidth * widthRatio;

    if (videoHeight < screenHeight) {
      videoHeight = screenHeight;
      videoWidth = screenHeight * heightRatio;
    }

    setSize({
      width: videoWidth,
      height: videoHeight,
    });

    /* webcamRef.current.video.width = videoWidth;
    webcamRef.current.video.height = videoHeight;

    webcamRef.current.style.width = videoWidth + "px";
    webcamRef.current.style.height = videoHeight + "px"; */
  };

  const playStatus = () => {
    if (playSt) {
      vidRef.current.pause();
      setPlaySt(false);
    } else {
      vidRef.current.play();
      setPlaySt(true);
    }
  };

  useEffect(() => {
    let width = window.innerWidth;
    if (width > 720) {
      setVidConst("user");
    }
    adjustCamera();
  }, []);

  return (
    <div className="gl-page gl-page--preview">
      <Webcam
        className="gl-g0001-camera"
        audio={false}
        ref={webcamRef}
        height={size.height}
        width={size.width}
        videoConstraints={{ facingMode: { exact: vidConst } }}
      />

      <div className="gl-g0001-epic">
        <div className="gl-g0001-epic__video">
          <video
            onEnded={() => {
              vidRef.current.pause();
              setStep(4);
            }}
            ref={vidRef}
            playsInline
            autoPlay
          >
            <source
              src="https://mdhost.ca/glypix/vid/g0001-beta.mov"
              type='video/mp4; codecs="hvc1"'
            />
            <source src={sample} type="video/webm" />
          </video>
        </div>
      </div>

      <div className="gl-g0001-epic-controls">
        <div className="gl-g0001-epic-controls__header">
          <div className="gl-wrapper">
            <div className="gl-align-right">
              <a
                href="#"
                onClick={() => {
                  setStep(4);
                }}
                className="gl-button gl-button--primary gl-button--reverse gl-button--inline gl-button--no-margin"
              >
                <i className="fa fa-right-from-bracket"></i> Exit
              </a>
            </div>
          </div>
        </div>
        <div className="gl-g0001-epic-controls__footer">
          <div className="gl-wrapper">
            <div className="gl-align-center">
              <a
                href="#"
                className="gl-button gl-button--primary gl-button--reverse"
                onClick={playStatus}
              >
                <i className={"fa fa-fw fa-" + (playSt ? "pause" : "play")}></i>
                {playSt ? "Pause" : "Play"}
              </a>
            </div>
          </div>

          <div className="gl-tag" style={{ background: "#fff" }}>
            <div className="gl-tag__copy">Presented by</div>
            <div className="gl-tag__logo">
              <Link to="/">
                <img src={logoGly} alt="Glypix logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exp;
