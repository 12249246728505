import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Subscribe from "../../Subscribe";
import iconLight from "../../../images/icon-g0000-light.svg";
import logo from "../../../images/g0000-wordmark.svg";
import logoGly from "../../../images/logo-glypix.svg";

const Start = ({ step, setStep }) => {
  return (
    <div className="gl-page gl-page--preview gl-page__g0000">
      <div className="gl-modal">
        <div className="gl-modal__content">
          <div className="gl-epic-logo-vertical">
            <div className="gl-epic-logo-vertical__icon">
              <img src={iconLight} alt="Icon for A Window to Zion Experience" />
            </div>
            <div className="gl-epic-logo-vertical__wordmark">
              <img
                src={logo}
                alt="Logo word mark for A Window to Zion Experience"
              />
            </div>
            <div className="gl-align-center">
              <small></small>
            </div>
            <div className="gl-tag">
              <div className="gl-tag__copy">Presented by</div>
              <div className="gl-tag__logo">
                <Link to="/">
                  <img src={logoGly} alt="Glypix logo" />
                </Link>
              </div>
            </div>
          </div>

          <div className="gl-align-center">
            <h5 class="gl-heading-medium">Coming Soon!</h5>
          </div>

          <div className="gl-social-menu gl-align-center">
            <div className="gl-social-menu__heading">
              <p>
                &nbsp;
                <br />
                Stay up to date and join the conversation.
              </p>
            </div>
            <Subscribe cpage="start" />
            <br />
            <br />
            <ul className="gl-list-inline gl-list-inline--centered">
              <li>
                <a
                  href="https://www.facebook.com/glypix"
                  className="gl-button-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-lg fa-facebook-square"></i>
                  <span className="screen-reader-text">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/glypix_history/"
                  className="gl-button-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-lg fa-instagram"></i>
                  <span className="screen-reader-text">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@glypix_history"
                  className="gl-button-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-lg fa-tiktok"></i>
                  <span className="screen-reader-text">TikTok</span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/Glypix_history"
                  className="gl-button-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-lg fa-twitter"></i>
                  <span className="screen-reader-text">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@glypix/featured"
                  className="gl-button-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-lg fa-youtube"></i>
                  <span className="screen-reader-text">Youtube</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="gl-page__g0001-backdrop"></div> */}
    </div>
  );
};

export default Start;
