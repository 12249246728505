import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { red } from "@mui/material/colors";
import MapContainer from "./MapContainer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Glyp = () => {
  const params = useParams();
  const navigate = useNavigate();
  const gkey = "AIzaSyAFdKQAqarLhhi1HB0n0OggrHRqUINoY6g";
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  useEffect(() => {
    if (params.code != "g0000" && params.code != "g0001") {
      navigate("/");
    }
  });

  return (
    <div className="gl-page">
      <section className="gl-section" id="explore-discover">
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1">
              <h2 className="gl-heading-large">
                {params.code == "g0000"
                  ? "Viola Desmond Experience"
                  : params.code == "g0001"
                  ? "African Methodist Episcopal Zion Church"
                  : ""}
              </h2>
              <div className="gl-content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p>
              </div>
            </div>
            <div className="gl-layout__item col-1/1">
              <h2 className="gl-heading-large">Plan your visit</h2>
              <div className="gl-content">
                <MapContainer />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gl-section">
        <div className="gl-wrapper gl-wrapper--narrow">
          <div className="gl-layout gl-layout--middle">
            <div className="gl-layout__item col-1/1 col-1/2@desktop">
              <h2 className="gl-heading-large">Why you should care</h2>
              <div className="gl-content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type.
                </p>
              </div>
            </div>
            <div className="gl-layout__item col-1/1 col-1/2@desktop">
              <Box
                component="img"
                alt="Why you should care"
                src={"../why_care.jpg"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Glyp;
