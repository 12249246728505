import React, { useState, useRef } from "react";

import { Link } from "react-router-dom";
import logo from "../images/logo-g0001.svg";
import logoGly from "../images/logo-glypix.svg";

const NotFound = ({ step, setStep }) => {
  return (
    <div className="gl-page">
      <div className="gl-page gl-page--preview">
        <div className="gl-g0001-content">
          <div className="gl-guide-step">
            <div className="gl-guide-step__content">
              <div className="gl-wrapper gl-wrapper--thin">
                <div className="gl-align-center">
                  <h1 style={{ fontSize: "5rem" }}>404</h1>
                  <p className="gl-heading-medium">Page Not Found.</p>
                </div>
              </div>
            </div>

            <div className="gl-guide-step__footer">
              <div className="gl-wrapper gl-wrapper--thin">
                <div className="gl-align-center">
                  <Link to="/" className="gl-button gl-button--primary">
                    <i className="fa fa-home"></i> Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
