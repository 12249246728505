import React, { useState, useEffect, useRef } from "react";
import { isSafari } from "react-device-detect";
import sample from "../images/vid/g0001-beta.webm";

const Safari = () => {
  const [step, setStep] = useState(0);

  return (
    <div className="gl-page">
      <div className="gl-page gl-page--preview">
        {step == 0 && (
          <div className="">
            <h2>First</h2>
            <video width="600" playsInline autoPlay>
              <source
                src="https://mdhost.ca/glypix/vid/g0001-beta.mov"
                type="video/mp4;codecs=hvc1"
              />
              <source src={sample} type="video/webm" />
            </video>
          </div>
        )}
        {step == 1 && (
          <div className="">
            <h2>Second</h2>
            <video width="600" playsInline autoPlay>
              <source
                src={
                  isSafari
                    ? "https://mdhost.ca/glypix/vid/g0001-beta.mov"
                    : sample
                }
                type={isSafari ? "video/quicktime" : "video/webm"}
              ></source>
            </video>
          </div>
        )}
        {step == 2 && (
          <div className="">
            <h2>Third</h2>
            <video width="600" playsInline autoPlay>
              <source
                src="https://mdhost.ca/glypix/vid/g0001-beta.mov"
                type="video/quicktime"
              />
              <source src={sample} type="video/webm" />
            </video>
          </div>
        )}
        {step == 3 && (
          <div className="">
            <h2>Fourth</h2>
            <video width="600" playsInline autoPlay>
              <source
                src="https://mdhost.ca/glypix/vid/g0001-beta.mov"
                type="video/quicktime;codecs=hvc1"
              />
              <source src={sample} type="video/webm" />
            </video>
          </div>
        )}
      </div>
      <a href="#" onClick={() => setStep(0)}>
        First
      </a>
      <br />
      <a href="#" onClick={() => setStep(1)}>
        Second
      </a>
      <br />
      <a href="#" onClick={() => setStep(2)}>
        Third
      </a>
      <br />
      <a href="#" onClick={() => setStep(3)}>
        Fourth
      </a>
    </div>
  );
};

export default Safari;
