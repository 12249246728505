import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";

import g0000Thumb from "../images/image-g0000-thumbnail.jpg";
import g0000Icon from "../images/icon-g0000.svg";
import g0000Logo from "../images/g0000-wordmark.svg";
import g0000Story from "../images/image-g0000-story.png";
import MapContainer from "./MapContainer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Zion = () => {

  const showcases = [
    {
      title: "Recognizing the African Methodist Episcopal Zion Church",
      url: "https://www.halifax.ca/about-halifax/diversity-inclusion/african-nova-scotian-affairs/recognizing-african-methodist",
      img: "https://cdn.halifax.ca/sites/default/files/pages/in-content/2023-01/picture11.jpg",
      desc: "",
    },
    {
      title: "Shining a light on forgotten African Nova Scotian history on Gottingen Street",
      url: "https://www.saltwire.com/atlantic-canada/news/shining-a-light-on-forgotten-african-nova-scotian-history-on-gottingen-street-100822689/",
      img: "https://saltwire.imgix.net/2023/2/8/shining-a-light-on-rich-african-nova-scotian-history-forgotte_K3sPsD5.jpg?cs=srgb&fit=crop&h=568&w=847&dpr=1&auto=enhance%2Ccompress%2Cformat",
      desc: "",
    },
    {
      title: "VOICE OF THE PEOPLE: AME Church has rich history",
      url: "https://www.saltwire.com/atlantic-canada/opinion/voice-of-the-people-ame-church-has-rich-history-100825846/",
      img: "https://saltwire.imgix.net/2023/2/17/voice-of-the-people-ame-church-has-rich-history-1.jpg?cs=srgb&fit=crop&h=568&w=847&dpr=1&auto=enhance%2Ccompress%2Cformat",
      desc: "",
    },
  ];

  useEffect(() => {
    document.title = "GLYPiX - A Window to Zion";
    window.scrollTo(0, 0);
  });

  return (
    <div className="gl-page">
      <section className="gl-section gl-section--small">
        <div className="gl-embellishment one"></div>
        <div className="gl-row gl-row--large">
          <div className="gl-wrapper">
            <div className="gl-layout">
              <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
                <div className="gl-page-thumbnail">
                  <img src={g0000Thumb} alt="" />
                </div>

                <h1>
                  <span className="gl-epic-logo">
                    <span className="gl-epic-logo__icon">
                      <img src={g0000Icon} alt="" />
                    </span>
                    <span className="gl-epic-logo__wordmark">
                      <img src={g0000Logo} alt="" />
                    </span>
                  </span>
                  <span className="screen-reader-text">
                    A Window to Zion
                  </span>
                </h1>
                <br />
                <h2 className="gl-heading-medium">Take a journey and discover one of Halifax’s hidden gems, the African Methodist Episcopal Zion Church. A former cornerstone of the African Nova Scotian community, this landmark has a special historic, and musical connection.</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
              <div className="gl-row">
                <div className="gl-layout">
                  <div className="gl-layout__item col-1/1 col-1/2@tablet">
                    <h3 className="gl-heading-small">Installation</h3>
                    <p>
                      <span>Charlie Marven</span>
                      <br />
                      <span>2023</span>
                      <br />
                      <span>
                        Digital Illustration printed on acrylic panel
                      </span>
                    </p>
                  </div>

                  <div className="gl-layout__item col-1/1 col-1/2@tablet">
                    <h3 className="gl-heading-small">Production</h3>
                    <p>
                      <span>COMING SOON</span>
                      <br />
                      <span>Web, scrollytelling, animation</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
              <div className="gl-row">
                &copy; GLYPiX Virtual Media Archive Inc 2023. All rights
                reserved.
              </div>
              <div className="gl-row">
                This experience is protected under the laws of Canada and other
                countries. Unauthorized duplication, distribution, or exhibition
                may result in civil liability and criminal prosecution.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
          id="location"
          className="gl-section gl-section--small gl-section--epic-location"
      >
        <div className="gl-wrapper gl-row">
          <h2 className="gl-heading-large">Location</h2>
          <div>5510 Falkland Street, Halifax, NS</div>
        </div>
        <div className="gl-wrapper">
          <div className="gl-map">
            <MapContainer />
          </div>
        </div>
      </section>

      <section
          id="story"
          className="gl-section gl-section--small gl-section--epic-story"
      >
        <div className="gl-wrapper">
          <div className="gl-layout gl-layout--huge gl-layout--reverse">
            <div className="gl-layout__item col-1/1 col-1/2@tablet">
              <p>
                <img src={g0000Story} alt="AME Zion Church" />
              </p>
            </div>
            <div className="gl-layout__item col-1/1 col-1/2@tablet">
              <h2 className="gl-heading-large">Gone But Not Forgotten: Remembering Halifax's AME Zion Church</h2>
              <p>For more than a century the African Methodist Episcopal (AME) Zion Church has played an important role in the lives of African Nova Scotians in Halifax. It was said to have the most beautifully crafted stained glassed windows before they were destroyed in 1917 during the Halifax explosion. It was also said to have the best sounding choir in the city.</p>
              <p>The church not only provided religious services, but also served as a cultural and educational center for the community. After it was founded in 1846 the congregation grew rapidly, and eventually purchased a lot to construct a church building which was completed in 1850. A school building was later purchased that was used to help educate African Nova Scotian children.</p>
              <p>Henry Sylvester Williams, father of the Pan-African movement and one of the church's notable members, was the first Black student at Dalhousie University's Faculty of Law. He organized the first Pan-African Conference in London in 1900, bringing together Black political activists from different parts of the world including Britain, the U.S., the Caribbean, and Africa.</p>
              <p>Marcus Garvey, founder of the Universal Negro Improvement Association and African Communities League, and one of the world’s most important Black activists in history, spoke at the "Halifax Community Church", believed to be the Zion church, in 1937. During his visit, Garvey delivered a speech at Menelik Hall in Sydney, inspiring lyrics to Bob Marley's Redemption Song.</p>
              <p>After being renovated, the church was demolished in 1955 due to lack of funds and declining membership. It was Reverend Anna Lee Hunter who later picked up the torch and started a new AME Zion Church in 1976. The congregation grew large enough to purchase a new building in 1980, located only two blocks from the original. Unfortunately, the new church also faced financial difficulties and eventually closed in 1986.</p>
              <p>Reverend Hunter's involvement in the Black community had a lasting impact. She helped many students with learning disabilities and served on various boards and committees. Her commitment was so great that she would make round trips daily from Sydney to Halifax by train, to support the community and help those in need. She also became a founding member of the Black Cultural Society of Nova Scotia.</p>
              <p>The AME Zion Church continues to be active in several parts of the world, with over 2.5 million members today. Although the congregation in Halifax has closed, the church's legacy endures through its continued presence worldwide.</p>
            </div>
          </div>
        </div>
      </section>

      <section
          id="showcase"
          className="gl-section gl-section--small gl-section--epic-showcase"
      >
        <div className="gl-wrapper">
          <h2 className="gl-heading-large">Showcase</h2>
          <div className="gl-carousel">
            <ul className="gl-carousel__list">
              {showcases.map(({ title, url, img, desc }, idx) => (
                  <li className="gl-carousel__item" key={idx}>
                    <div className="gl-card gl-card--alt">
                      <div className="gl-card__content">
                        <div className="gl-card__thumbnail">
                          <a target="_blank" href={url}>
                            <img src={img} alt={title} />
                          </a>
                        </div>
                        <div className="gl-card__details">
                          <h3 className="gl-card__title">
                            <a target="_blank" href={url}>
                              <i className="gl-icon icon-g0000"></i>
                              {title}
                            </a>
                          </h3>
                          <div className="gl-card__location"></div>
                          {desc !== "" && (
                              <div className="gl-card__description">{desc}</div>
                          )}
                        </div>
                      </div>
                      <div className="gl-card__hover"></div>
                    </div>
                  </li>
              ))}
            </ul>
          </div>
          <div>{/* INSTAGRAM FEED */}</div>
        </div>
      </section>

      {/* NOTE:
    The links in the showcase will only serve as external
    links to press articles, or a YouTube video player overlay,
    similar to our preview video on the front page. */}

      <section id="contact" className="gl-section gl-section--small">
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-3/5@desktop">
              <h2 className="gl-heading-large">
                What story do you want to celebrate?
              </h2>
              <div className="gl-content">
                <p>
                  Contact us to find out how you can be involved, and help bring
                  the next Glypix project to life. We’re looking to establish
                  meaningful partnerships with communities, government
                  organizations, developers, culture and heritage groups,
                  museums, galleries and educators.
                </p>
                <p>
                  If you're a talented creative professional looking for a
                  challenge, we'd love to chat.
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="gl-embellishment three"></div>
      </section>
    </div>
  );
};

export default Zion;
