import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import Subscribe from "./Subscribe";
import logoGlypix from "../images/logo-glypix.svg";
//import imageVde from "../images/image-g0001-thumbnail.jpg";
import imageVde from "../images/image-g0001-thumbnail.jpeg";
//import imageZion from "../images/g0000-background.jpg";
import imageZion from "../images/image-g0000-thumbnail.jpg";
import comingSoon from "../images/image-coming-soon.jpg";
import spinner from "../spinner.gif";

const Home = () => {
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  useEffect(() => {
    document.title =
      "Preserving Culture + History with Art, Technology, Multimedia";
  }, []);

  return (
    <div className="gl-page">
      {showVideo && (
        <div className="gl-preview-video" onClick={toggleVideo}>
          <div className="gl-wrapper">
            <div className="gl-preview-video__ar">
              <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cf-jVTbtFdg?vq=hd720&autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="gl-align-center close-cont">
            <a href="#" onClick={toggleVideo}>
              &times; Close
            </a>
          </div>
        </div>
      )}
      <section className="gl-splash">
        <div className="gl-wrapper">
          <div className="gl-splash__logo">
            <img src={logoGlypix} alt="Glypix Logo" />
          </div>

          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-2/3@tablet col-push-1/3@tablet col-1/2@desktop col-push-1/2@desktop">
              <div className="gl-splash__tagline">
                <h1 className="gl-heading-huge">Rediscover. Reimagine.</h1>
              </div>
              <div className="gl-splash__cta">
                <p>
                  Glypix showcases and preserves culturally significant history
                  through the combined use of art, multimedia and technology.
                </p>
                <a
                  href="#"
                  onClick={toggleVideo}
                  className="gl-button gl-button--primary gl-button--large"
                >
                  <i className="fa fa-play fa-fw"></i> Watch preview
                </a>
              </div>

              <div className="gl-splash__scroll">
                <a href="/#mission">
                  <span>Learn more</span>
                  <span style={{ display: "block" }}>
                    <i className="fa fa-chevron-down fa-xs"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gl-section" id="mission">
        <div className="gl-embellishment one"></div>
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-4/5@tablet col-1/2@desktop">
              <div className="gl-content">
                <h2 className="gl-heading-large">
                  Stay in the loop and join the conversation
                </h2>
                <p>
                  Give us your email and we'll notify you when GLYPiX goes live.
                  Follow us on social media to see behind the scenes of upcoming
                  projects.
                </p>

                <Subscribe />
                <div className="gl-hide@tablet">
                  <br />
                  <ul className="gl-list-inline gl-list-inline--centered">
                    <li>
                      <a
                        href="https://www.facebook.com/glypix"
                        className="gl-button-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-2x fa-facebook-square"></i>
                        <span className="screen-reader-text">Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/glypix_history/"
                        className="gl-button-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-2x fa-instagram"></i>
                        <span className="screen-reader-text">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@glypix_history"
                        className="gl-button-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-2x fa-tiktok"></i>
                        <span className="screen-reader-text">TikTok</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Glypix_history"
                        className="gl-button-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-2x fa-twitter"></i>
                        <span className="screen-reader-text">Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@glypix/featured"
                        className="gl-button-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-2x fa-youtube"></i>
                        <span className="screen-reader-text">Youtube</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gl-section" id="explore-discover">
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-2/3@tablet col-1/2@desktop">
              <div className="gl-content">
                <h2 className="gl-heading-large">
                  <i className="gl-icon icon-glyp"></i> Explore & Discover
                </h2>
                <p>
                  A Glyp is what we call the colourful icon you see. It has a QR
                  code in the middle and you’ll find it on all Glypix landmarks
                  and art installations. Scan the QR code with your smart device
                  to activate the Glyp, then away you go!
                </p>
                <p>
                  Each Glyp immerses you in an epic story related to the
                  artwork. You can then discover more about the subject, and
                  take a look behind the scenes.
                </p>
              </div>
            </div>
          </div>

          <div className="gl-carousel">
            <ul className="gl-carousel__list">
              <li className="gl-carousel__item">
                <div className="gl-card">
                  <div className="gl-card__content">
                    <div className="gl-card__thumbnail">
                      <Link
                        to="/viola-desmond-experience/"
                        preventScrollReset={false}
                      >
                        <img src={imageVde} alt="Thumbnail for {Epic Title}" />
                      </Link>
                    </div>
                    <div className="gl-card__icon">
                      <i className="gl-icon icon-g0001"></i>
                    </div>
                    <div className="gl-card__details">
                      <h3 className="gl-card__title">
                        <Link
                          to="/viola-desmond-experience/"
                          preventScrollReset={false}
                        >
                          The Viola Desmond Experience
                        </Link>
                      </h3>
                      <div className="gl-card__location">
                        2300 Gottingen Street, Halifax, NS
                      </div>
                      <div className="gl-card__description">
                        Have a seat at the beauty studio of entrepreneur and
                        Canadian civil rights icon, Viola Desmond. Hear the
                        amazing story of how she impacted the Black beauty
                        industry in Canada.
                      </div>
                    </div>
                  </div>
                  <div className="gl-card__hover"></div>
                </div>
              </li>
              <li className="gl-carousel__item">
                <div className="gl-card">
                  <div className="gl-card__content">
                    <div className="gl-card__thumbnail">
                      <Link to="/window-to-zion">
                        <img
                          src={imageZion}
                          alt="Thumbnail for A Window to Zion"
                        />
                      </Link>
                    </div>
                    <div className="gl-card__icon">
                      <i className="gl-icon icon-g0000"></i>
                    </div>
                    <div className="gl-card__details">
                      <h3 className="gl-card__title">
                        <Link to="/window-to-zion">A Window to Zion</Link>
                      </h3>
                      <div className="gl-card__location">
                        5510 Falkland Street, Halifax, NS
                      </div>
                      <div className="gl-card__description">
                        Take a journey and discover one of Halifax’s hidden
                        gems, the African Methodist Episcopal Zion Church. A
                        former cornerstone of the African Nova Scotian
                        community, this landmark has a special historic, and
                        musical connection.
                      </div>
                    </div>
                  </div>
                  <div className="gl-card__hover"></div>
                </div>
              </li>
              <li className="gl-carousel__item">
                <div className="gl-card">
                  <div className="gl-card__content">
                    <div className="gl-card__thumbnail">
                      <img src={comingSoon} alt="Thumbnail for {Epic Title}" />
                    </div>

                    <div className="gl-card__details">
                      <h3 className="gl-card__title">COMING SOON</h3>
                      <div className="gl-card__location">
                        Another epic experience is on the way!
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="about-glypix" className="gl-section">
        <div className="gl-wrapper gl-wrapper--narrow">
          <div className="gl-layout gl-layout--middle">
            <div className="gl-layout__item col-1/1 col-1/2@desktop">
              <div className="gl-embellishment two"></div>
            </div>
            <div className="gl-layout__item col-1/1 col-1/2@desktop">
              <h2 className="gl-heading-large">About Glypix</h2>
              <div className="gl-content">
                <p>
                  Many of the stories that shape our communities are lost or
                  uncelebrated due to the natural development of society.
                </p>
                <p>
                  Glypix preserves and showcases these meaningful stories in a
                  way that can be embraced and enjoyed by all. We combine art,
                  multimedia and technology to create dynamic, interactive
                  viewing experiences.
                </p>
                <p>
                  The goal is to provide a unified platform to engage everyone,
                  regardless of background. Glypix invites all to embrace and
                  celebrate new cultures and experiences. And build awareness
                  and compassion toward one another.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="gl-section">
        <div className="gl-wrapper">
          <div className="gl-layout">
            <div className="gl-layout__item col-1/1 col-3/5@desktop">
              <h2 className="gl-heading-large">
                What story do you want to celebrate?
              </h2>
              <div className="gl-content">
                <p>
                  Contact us to find out how you can help bring the next Glypix
                  project to life. We want to establish meaningful partnerships
                  with communities, government organizations, developers,
                  culture and heritage groups, museums, galleries, educators,
                  and creative professionals.
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="gl-embellishment three"></div>
      </section>
    </div>
  );
};

export default Home;
