import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";
import logo from "../../../images/logo-g0001.svg";
import logoGly from "../../../images/logo-glypix.svg";
import gFigure2 from "../../../images/g0001-guide-figure-2.png";
import sample from "../../../images/vid/g0001-beta.webm";
import Webcam from "react-webcam";

const Sec = ({ step, setStep, zoom, setZoom, maxZoom }) => {
  const webcamRef = useRef(null);

  const [vidConst, setVidConst] = useState("environment");
  const [cameraOn, setCameraOn] = useState(false);
  const [cameraOffMsg, setCameraOffMsg] = useState("Waiting for the camera...");

  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  const [size, setSize] = useState({
    width: screenWidth,
    height: screenHeight,
  });

  const adjustCamera = (cnt) => {
    if (cnt > 100) {
      setCameraOffMsg(
        "Unable to start camera. Please make sure you are on mobile device and camera access is allowed."
      );
    }
    if (
      typeof webcamRef.current === "undefined" ||
      webcamRef.current === null ||
      webcamRef.current.video.readyState !== 4
    ) {
      setTimeout(() => {
        adjustCamera(cnt + 1);
      }, 100);
      return;
    }

    setCameraOn(true);

    let videoWidth = webcamRef.current.video.videoWidth;
    let videoHeight = webcamRef.current.video.videoHeight;

    let widthRatio = videoHeight / videoWidth;
    let heightRatio = videoWidth / videoHeight;

    videoWidth = screenWidth;
    videoHeight = screenWidth * widthRatio;

    if (videoHeight < screenHeight) {
      videoHeight = screenHeight;
      videoWidth = screenHeight * heightRatio;
    }

    setSize({
      width: videoWidth,
      height: videoHeight,
    });

    /* webcamRef.current.video.width = videoWidth;
    webcamRef.current.video.height = videoHeight;

    webcamRef.current.style.width = videoWidth + "px";
    webcamRef.current.style.height = videoHeight + "px"; */
  };

  useEffect(() => {
    let width = window.innerWidth;
    if (width > 720) {
      setVidConst("user");
    }

    adjustCamera(0);
  }, []);

  return (
    <div
      className={"gl-page gl-page--preview gl-g0001-camera-zoom zoom-" + zoom}
    >
      <Webcam
        className="gl-g0001-camera"
        audio={false}
        ref={webcamRef}
        height={size.height}
        width={size.width}
        videoConstraints={{ facingMode: { exact: vidConst } }}
        style={{ opacity: 0.5 }}
      />
      <div className="gl-g0001-epic">
        <div className="gl-g0001-epic__video">
          <div className="gl-0001-epic__video-placement-guide">
            <img src={gFigure2} alt="Guide Figure 2" />
          </div>
          <video muted playsInline style={{ opacity: 0 }}>
            <source
              src="https://mdhost.ca/glypix/vid/g0001-beta.mov"
              type='video/mp4; codecs="hvc1"'
            />
            <source src={sample} type="video/webm" />
          </video>
        </div>
      </div>

      <div className="gl-g0001-content">
        <div className="gl-guide-step">
          <div className="gl-guide-step__header">
            <div
              className="gl-wrapper gl-wrapper--narrow"
              style={{ padding: 0 }}
            >
              <div className="gl-epic-logo-vertical">
                {/* <div className="gl-epic-logo-vertical__wordmark">
                  <img
                    src={logo}
                    alt="Logo word mark for The Viola Desmond Experience"
                  />
                </div>
                <div className="gl-align-center">
                  <small>(Ver 1.0)</small>
                </div> */}
                <div className="gl-tag" style={{ width: "100%" }}>
                  <div className="gl-tag__copy">Presented by</div>
                  <div className="gl-tag__logo">
                    <Link to="/">
                      <img src={logoGly} alt="Glypix logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gl-guide-step__content mfix">
            <div className="gl-wrapper gl-wrapper--thin">
              <div className="gl-align-center"></div>
            </div>
          </div>

          <div className="gl-align-center">
            <a
                href="#"
                className={
                    "gl-button gl-button--inline gl-button--reverse " +
                    (zoom <= 0 ? "gl-button--disabled" : "")
                }
                onClick={() => {
                  setZoom(zoom - 1);
                }}
            >
              <i
                  className="fa fa-fw fa-search-minus"
                  aria-hidden="true"
              ></i>
              <span className="screen-reader-text">Zoom in</span>
            </a>
            <a
                href="#"
                className={
                    "gl-button gl-button--inline gl-button--reverse " +
                    (zoom >= maxZoom ? "gl-button--disabled" : "")
                }
                onClick={() => {
                  setZoom(zoom + 1);
                }}
            >
              <i className="fa fa-fw fa-search-plus" aria-hidden="true"></i>
              <span className="screen-reader-text">Zoom in</span>
            </a>
          </div>

          <div className="gl-guide-step__footer">
            <div className="gl-wrapper gl-wrapper--thin">
              <div className="gl-align-center">&nbsp;</div>
              <div className="gl-align-center">
                <p className="gl-heading-medium">
                  {cameraOn &&
                    "Use the zoom buttons to adjust your position and place your head in the circle. Click next to begin."}
                  {!cameraOn && (
                    <p className="gl-heading-medium camera-wait">
                      {cameraOffMsg}
                    </p>
                  )}
                </p>
              </div>
              <div className="gl-align-center">
                {cameraOn && (
                  <a
                    href="#"
                    onClick={() => {
                      setStep(3);
                    }}
                    className="gl-button gl-button--primary-not-hover gl-button--no-margin"
                  >
                    Next <i className="fa fa-angle-right"></i>
                  </a>
                )}
              </div>
              <div className="gl-align-center">&nbsp;</div>
              <div className="gl-align-center">
                <ul className="gl-step-nav">
                  <li className="gl-step-nav__item">
                    <span>Step 1</span>
                  </li>
                  <li className="gl-step-nav__item current">
                    <span>Step 2</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec;
