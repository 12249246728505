import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoGlypix from "../images/logo-glypix.svg";
import { isMobile } from "react-device-detect";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));

      if (!elementToScroll) return;

      if (isMobile) {
        window.scrollTo({
          top: elementToScroll.offsetTop - 50,
          behavior: "smooth",
        });
      } else {
        document.getElementsByClassName("gl-page")[0].scrollTo({
          top: elementToScroll.offsetTop - 50,
          behavior: "smooth",
        });
      }
    };

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  });
  return (
    <div className={"gl-wrapper" + (showMenu ? " menu-active" : "")}>
      <div className="gl-layout gl-layout--middle">
        <div className="gl-layout__item col-1/2 col-1/3@tablet">
          <div className="gl-header__logo">
            <Link to="/">
              <img src={logoGlypix} alt="Glypix Logo" />
            </Link>
          </div>
        </div>

        <div className="gl-layout__item col-1/2 col-2/3@tablet">
          <div className="gl-header__nav">
            <Link
              to="#"
              className={
                "js-toggle-menu gl-button-icon" +
                (showMenu ? " menu-active" : "")
              }
              onClick={toggleMenu}
            >
              <i className="fa fa-bars show"></i>
              <i className="fa fa-times hide"></i>{" "}
              <span className="screen-reader-text">Toggle Menu</span>
            </Link>

            <div className="gl-header-menu">
              <nav>
                <ul>
                  <li className="gl-header-menu__toggle">
                    <Link
                      to="#"
                      className={
                        "js-toggle-menu gl-button-icon" +
                        (showMenu ? " menu-active" : "")
                      }
                      onClick={toggleMenu}
                    >
                      <i className="fa fa-times fa-2x"></i>
                      <span className="screen-reader-text">Close menu</span>
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/#explore-discover"
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      Explore & Discover
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#about-glypix"
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      About Glypix
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#contact"
                      onClick={() => {
                        setShowMenu(false);
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
