import React, { useState, useEffect } from "react";

import Start from "./Start";
import First from "./First";
import Sec from "./Sec";
import Exp from "./Exp";
import Desktop from "./Desktop";

const Preview0001 = () => {
  const [step, setStep] = useState(0);
  const [zoom, setZoom] = useState(0);
  const maxZoom = 2;

  useEffect(() => {
    document.title = "GLYPiX - The Viola Desmond Experience";
  }, []);

  return (
    <>
      {(step == 0 || step == 4) && <Start step={step} setStep={setStep} />}
      {step == 1 && <First step={step} setStep={setStep} />}
      {step == 2 && (
        <Sec
          step={step}
          setStep={setStep}
          zoom={zoom}
          setZoom={setZoom}
          maxZoom={maxZoom}
        />
      )}
      {step == 3 && (
        <Exp
          step={step}
          setStep={setStep}
          zoom={zoom}
          setZoom={setZoom}
          maxZoom={maxZoom}
        />
      )}
      {step == 5 && <Desktop step={step} setStep={setStep} />}
      <div className="c-landscape-scrim">
        <div className="c-landscape-scrim__content">
          Our site is best viewed in portrait mode.
          <div className="c-landscape-scrim__graphic" />
        </div>
      </div>
    </>
  );
};

export default Preview0001;
