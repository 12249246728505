import React, { useState, useRef } from "react";

import { Link } from "react-router-dom";
import logo from "../../../images/logo-g0001.svg";
import logoGly from "../../../images/logo-glypix.svg";
import gFigure1 from "../../../images/g0001-guide-figure-1.png";

const First = ({ step, setStep }) => {
  return (
    <div className="gl-page gl-page--preview">
      <div className="gl-g0001-content">
        <div className="gl-guide-step">
          <div className="gl-guide-step__header">
            <div className="gl-wrapper gl-wrapper--narrow">
              <div className="gl-epic-logo-vertical">
                <div className="gl-epic-logo-vertical__wordmark">
                  <img
                    src={logo}
                    alt="Logo word mark for The Viola Desmond Experience"
                  />
                </div>
                <div className="gl-align-center">
                  <small>(Ver 1.0)</small>
                </div>
                <div className="gl-tag">
                  <div className="gl-tag__copy">Presented by</div>
                  <div className="gl-tag__logo">
                    <Link to="/">
                      <img src={logoGly} alt="Glypix logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gl-guide-step__content">
            <div className="gl-wrapper gl-wrapper--thin">
              <div className="gl-align-center">
                <p className="gl-aspect-ratio gl-aspect-ratio--4:3 gl-aspect-ratio--contain">
                  <span className="gl-aspect-ratio__content">
                    <img src={gFigure1} alt="Chair and mirror line art" />
                  </span>
                </p>
                <p className="gl-heading-medium">
                  Have a seat in the chair and face the mirror. Once you're in
                  position, click "Next".
                </p>
              </div>
            </div>
          </div>

          <div className="gl-guide-step__footer">
            <div className="gl-wrapper gl-wrapper--thin">
              <div className="gl-align-center">
                <ul className="gl-step-nav">
                  <li className="gl-step-nav__item current">
                    <span>Step 1</span>
                  </li>
                  <li className="gl-step-nav__item">
                    <span>Step 2</span>
                  </li>
                </ul>
              </div>

              <div className="gl-align-center">
                <a
                  href="#"
                  onClick={() => {
                    setStep(2);
                  }}
                  className="gl-button gl-button--primary gl-button--no-margin"
                >
                  Next <i className="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
