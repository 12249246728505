import * as React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const Glypix = () => {
  return (
    <div className="gl-page">
      <Grid container>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12} />
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <main>
            <h2>Correct QR code</h2>
            <p>Welcome to GLYPiX.</p>
            <p>
              <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
              >
                Back To Home
              </Button>
            </p>
          </main>
        </Grid>
      </Grid>
    </div>
  );
};

export default Glypix;
